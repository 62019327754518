import React, { useEffect, useState, useTransition, useRef,useContext } from 'react';
import { NavLink, Link, useLocation,useNavigate } from 'react-router-dom';
import { RiLoginCircleLine } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { IoHeartOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FiSearch } from "react-icons/fi";

import { LuChevronRight } from "react-icons/lu";
import { GrClose } from "react-icons/gr";
import { TbHome2, TbBookmarks, TbBriefcase, TbDeviceIpadHorizontalCheck, TbMessage, TbBuilding, TbHeartHandshake, TbMessage2Search, TbMessage2Heart, TbLogout } from "react-icons/tb";
import { IoMdGlobe } from "react-icons/io";
import { BsPassport } from "react-icons/bs";
import { BiImages, BiSupport } from "react-icons/bi";
import axios from 'axios';
import Select from "react-select";
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';
import logoImg from '../../assets/images/logo.png'
import { LoginPopupContext } from '../loginpopup/LoginPopupContext';
import { SearchContext } from "../searchcontext/SearchContexts";
import placeHolderImg from '../../assets/images/user.png'
import { useGlobalState } from '../globalProvider/globalProvider';
const path = "";

// const designations = [
//     { value: "developer", label: "Developer" },
//     { value: "designer", label: "Designer" },
//     { value: "manager", label: "Manager" },
//     // Add more options here
// ];

// const locations = [
//     { value: "new-york", label: "New York" },
//     { value: "san-francisco", label: "San Francisco" },
//     { value: "los-angeles", label: "Los Angeles" },
//     // Add more options here
// ];

const experiences = [
    { value: "0-1", label: "0-1 Yrs" },
    { value: "2-5", label: "2-5 Yrs" },
    { value: "6-10", label: "6-10 Yrs" },
    // Add more options here
];

function Nav({onSearchpage}) {
setTimeout(() => {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('login_id');
  updateToken();
  setcandidatenameval(null)
  setcandidatenumber(null)
  setroleval(null)
}, 86400000);


    const [isClearable] = useState(true);

    const location = useLocation();
    const [inatialImage,setInatialImage] = useState();
    const sideMenu = document.querySelector('.sidemenu');
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const navSearchStrip = document.querySelector('.navSearchStrip');
    const [isSideActive,setIsSideActive] = useState(false);
    const [isSearchActive,setIsSearchActive] = useState(false);
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useContext(LoginPopupContext);
    const inputRef = useRef(null);
    const [gettokenval, settokenval] = useState(null);
    const [roleval, setroleval] = useState(null);
    const navigate = useNavigate();
    const [jobTitles, setjobTitles] = useState(null);
    const [locations, setlocations] = useState(null);
    const { globalState, setGlobalState } = useGlobalState();
    const [selectedJobTitle, setSelectedJobTitle] = useState(null); 
    const [selectedLocation, setSelectedLocation] = useState(null); 
    const [selectedExperience, setSelectedExperience] = useState(null); 
    const [searchdata, setSearchdataget] = useState('');
    const { setsearchDatas } = useContext(SearchContext);

    // useEffect(() => {
    //   clearFilters();
    // }, [location]);

    useEffect(() => {
      const jwt_token_val=localStorage.getItem('jwtToken');
      const roleval=localStorage.getItem('roleval');
      //console.log(roleval)
      settokenval(jwt_token_val || null);
      setroleval(roleval)
      updateToken();
      window.addEventListener('storage', updateToken);
      return () => {
          window.removeEventListener('storage', updateToken);
      };
  }, []);
 const mediaPath = mediaUrl
 //job title section
 useEffect(() => {
  axios.get(`${baseURL}/fetch_all_job_titles`) // Replace with your API URL
      .then(response => {
      //console.log(response.data)
      const fromattedtags = response.data.map(jobtitle => ({
          value: jobtitle.job_title,
          label: jobtitle.job_title 
          }));
          setjobTitles(fromattedtags);
      })
      .catch(error => {
      console.error('Error fetching items:', error);
      });
  }, []); 

  //location section
  useEffect(() => {
  axios.get(`${baseURL}/fetch_all_location`) // Replace with your API URL
      .then(response => {
      //console.log(response.data)
      const fromattedtags = response.data.map(location_v => ({
          value: location_v.location_val,
          label: location_v.location_val 
          }));
          setlocations(fromattedtags);
      })
      .catch(error => {
      console.error('Error fetching items:', error);
      });
  }, []);

    //alert(isLoginPopupVisible)
    useEffect(() => {
      const shimmer = document.querySelector('.shimmer'); // Access shimmer inside useEffect
      const tokenval = localStorage.getItem('jwtToken');
      const roleval1=localStorage.getItem('roleval');
      if (tokenval == ''||tokenval==null && roleval1!='candidate'||roleval1==undefined ||roleval1==null) {
      if (shimmer) { // Check if shimmer exists
          const timer = setTimeout(()=> {
              setIsLoginPopupVisible(true);
              shimmer.style.display = 'block';
          }, 5000); // Show loginPopup after 5 seconds

          return () => clearTimeout(timer); // Cleanup the timer
      }
    }
    }, []);
    
    const updateToken = () => {
      const tokenval = localStorage.getItem('jwtToken');
      settokenval(tokenval || null);
  };



    const closeloginPopup = () => {
      setIsLoginPopupVisible(false);
      if (shimmer) {
          shimmer.style.display = 'none';
      }
  }

    const isActiveJob = () => {
        return location.pathname.includes(`/jobs`) || location.pathname.includes(`/jobDetails`);
    };
    const isCountriesJob = () => {
        return location.pathname.includes(`/immigration`) || location.pathname.includes(`/immigrationDetails`);
    };
    const isBlogJob = () => {
        return location.pathname.includes(`/blog`) || location.pathname.includes(`/innerBlog`);
    };
    const isServiceJob = () => {
        return location.pathname.includes(`/service`) || location.pathname.includes(`/innerService`);
    };

    const [isPending, startTransition] = useTransition();
    const [data, setData] = useState(null);
    const [candidatenameval,setcandidatenameval]=useState(null);
    const [candidatenumber,setcandidatenumber]=useState(null);
    useEffect(() => {
      startTransition(() => {
        // Simulating an async operation like fetching data
        setTimeout(() => {
          setData({ loaded: true });
        }, 1000);
      });

    const login_id_val = localStorage.getItem('login_id');
    axios.get(`${baseURL}/fetch_profile_details_candidate?id=${login_id_val}`)
      .then(response => {
        console.log(response.data)
        const first_name = response.data.first_name;
        const last_name = response.data.last_name;
        setcandidatenameval(`${first_name} ${last_name}`);
        if(response.data.profile_image){
          setGlobalState(mediaPath + response.data.profile_image)
        }
        const profileimage = localStorage.getItem('profileimage');
        console.log(profileimage)
        if(profileimage =='imageupdate'){
          setGlobalState(mediaPath + response.data.profile_image)
        }
        setcandidatenumber(response.data.country_code+ ' ' + response.data.phone_number)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }, []);
  

    

    
    if (isPending || !data) {
      return <div className='pageLoading'>
        <div className="spinLoader"></div>
      </div>;
    }

    const navBarClik = () => {
        setIsSideActive(!isSideActive)
        if (shimmer) {
            shimmer.style.display = 'block';
        }
        if (body) {
            body.style.overflow = 'hidden';
        }
    }

    const closeSidemenuClick = () => {
        setIsSideActive(!isSideActive)
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
        window.scrollTo(0, 0)
    }

    
    
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    const navSearchClick = () => {
      setIsSearchActive(!isSearchActive)
        if (shimmer) {
            shimmer.style.display = 'block';
        }
        if (body) {
            body.style.overflow = 'hidden';
        }
        if (inputRef.current) {
          inputRef.current.focus();
        }
    }

    const closeNavSearchStripClick = () => {
      setIsSearchActive(!isSearchActive)
      if (shimmer) {
          shimmer.style.display = 'none';
      }
      if (body) {
          body.style.overflow = 'inherit';
      }
  }


  //logout section
  const handleLogout = async () => {
    setIsSideActive(!isSideActive)
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
    try {
      const response = await axios.post(`${baseURL}/candidate_logout`, {
            jwt: localStorage.getItem('jwtToken')
        });
        //console.log(response.data)
        if (response.data.status === 'logged_out') {
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('login_id');
          localStorage.removeItem('roleval');
          updateToken();
          setcandidatenameval(null)
          setcandidatenumber(null)
          window.location.href = `${path}/login`;
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
     

  const searchBtnclick = (e) => {
    e.preventDefault();
    console.log(searchdata)
    setsearchDatas({
      Searchjob: searchdata,
    });
    setTimeout(() => {
      navigate('/jobs');
  }, 0); 
  // Clear the input value
  inputRef.current.value = '';
 setIsSearchActive(!isSearchActive)
      if (shimmer) {
          shimmer.style.display = 'none';
      }
      if (body) {
          body.style.overflow = 'inherit';
      }
    //onSearchpage(filters);
  };

  const handleSelectChange = (name, selectedOption) => {
    // Ensure selectedOption is not null or undefined
    if (selectedOption && selectedOption.value) {
      switch (name) {
        case 'jobtitle':
          setSelectedJobTitle(selectedOption.value);
          break;
        case 'location':
          setSelectedLocation(selectedOption.value);
          break;
        case 'experience':
          setSelectedExperience(selectedOption.value);
          break;
        default:
          break;
      }
    } else {
      // Handle case when no option is selected (clear or undefined)
      switch (name) {
        case 'jobtitle':
          setSelectedJobTitle([]);  // Default to empty array or desired value
          break;
        case 'location':
          setSelectedLocation([]);  // Default to empty array or desired value
          break;
        case 'experience':
          setSelectedExperience([]);  // Default to empty array or desired value
          break;
        default:
          break;
      }
    }
  };
  
  return (
    <>
    <nav>
      <div className="container">
        <div className="navMain">
          <div className="navCol1">
            <Link to={`${path}/`} className="navLogo">
              <img src={logoImg} alt="" />
            </Link>
            <div className="navLink">
              <ul>
                <li>
                  <NavLink to={`${path}/`} end className={({ isActive }) => (isActive ? 'navLinkActive' : '')} onClick={() => {window.scrollTo(0, 0);setsearchDatas({ Searchdata: "" }); }}>Home</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/jobs`} className={() => (isActiveJob() ? 'navLinkActive' : '')} onClick={() => {window.scrollTo(0, 0);setsearchDatas({ Searchdata: "" }); }}>Jobs</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/immigration`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')} onClick={() => {window.scrollTo(0, 0);setsearchDatas({ Searchdata: "" }); }}>Immigration</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/testimonials`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')} onClick={() => {window.scrollTo(0, 0);setsearchDatas({ Searchdata: "" }); }}>Testimonials</NavLink>
                </li>
                {/* <li>
                  <NavLink to={`${path}/service`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Service</NavLink>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="navCol2">
            <div className="navSearch">
              <div className="navSearchBox" onClick={navSearchClick}>
                <p>Search job...</p>
                <LuSearch />
              </div>
            </div>
            {/* <div className="navBtnArea">
              <Link className="navBtnAreaBox">
                <IoMdNotificationsOutline />
                <div className="notificationActiveIcon"></div>
                <div className="navDropDown">
                  <ul>
                    <li>
                      <Link to={`${path}/jobs`}>
                        <h2>Recommended jobs for you</h2>
                        <p>Web Developer</p>
                      </Link>
                    </li>
                    <li>
                      <Link to={`${path}/jobs`}>
                        <h2>Recommended jobs for you</h2>
                        <p>UI Designer</p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
            </div> */}
            <div className="navLogin">
              <div className='navProfileBtn' onClick={navBarClik}>
                <div className="navProfileNavBar">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="navProfileIcon">
                    <FaUserAlt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div className={`navSearchStrip ${isSearchActive && 'navSearchStripActive'}`}>
      <div className="closeNavSearchStrip" onClick={closeNavSearchStripClick}>
        <GrClose />
      </div>
      <div className="container">
        <div className="navSearchStripMain">
            <h2 className='title'>Search</h2>
            <form action="">
                <div className="formGroupBox">
                    <div className="formGroup">
                    <input 
                    type="text" 
                    name="searchval" 
                    value={searchdata} 
                    onChange={(e) =>{ setSearchdataget(e.target.value) 
                      console.log(e.target.value)}}  
                    />
                        {/* <Select
                            options={jobTitles}
                            placeholder="Job Title"
                            className="customSelect"
                            isClearable={isClearable}
                            styles={customStyles}
                            onChange={(selected) => handleSelectChange('jobtitle', selected)}
                        /> */}
                    </div>
                    <div className="formGroup">
                        {/* <Select
                            options={locations}
                            placeholder="Location"
                            isSearchable={true}
                            className="customSelect"
                            isClearable={isClearable}
                            styles={customStyles}
                            onChange={(selected) => handleSelectChange('location', selected)}
                        /> */}
                    </div>
                    <div className="formGroup">
                        {/* <Select
                            options={experiences}
                            placeholder="Experience"
                            className="customSelect"
                            isClearable={isClearable}
                            styles={customStyles}
                            onChange={(selected) => handleSelectChange('experience', selected)}
                        /> */}
                    </div>
                </div>

                {/* only mobile search */}
                <div className="mobileSearchInput">
                  <input ref={inputRef} type="search" placeholder='Search Jobs...'
                  onChange={(e) =>{ setSearchdataget(e.target.value)}}  />
                </div>
                {/* only mobile search */}

                <div className="formBtnArea">
                    <button className="formSbtBtn" onClick={searchBtnclick}>
                        <FiSearch /> Search
                    </button>
                </div>
            </form>
        </div>
      </div>
    </div>

    <aside className={`sidemenu ${isSideActive && 'sidemenuActive'}`}>
        <div className="closeSidemenu" onClick={closeSidemenuClick}>
            <GrClose />
        </div>
        <div className="sidemenuHead">
            <div className="sidemenuHeadLogin"  style={{ display: gettokenval == '' || gettokenval == null &&  roleval=='' || roleval == null ? 'flex' : 'none' }} onClick={closeSidemenuClick}>
              <Link to={`${path}/login`} className='sidemenuLoginBtn'>Login <RiLoginCircleLine /></Link>
            </div>
            {gettokenval &&  roleval=='candidate' && (
            <div className="sidemenuHeadProfile">
              <div className="sidemenuHeadCol1">
                  <div className="sidemenuProfileIcon">
                  {globalState ? <img src={globalState || placeHolderImg} alt="" /> :<FaUserAlt/> }
                  
                  </div>
              </div>
              
              <div className="sidemenuHeadCol2">
                  <div className="sidemenuProfileName">{candidatenameval}</div>
                  <div className="sidemenuPhone">{candidatenumber}</div>
                  <Link to={`${path}/profile`} className='sidemenuProfileViewBtn' onClick={closeSidemenuClick}>View & update profile <LuChevronRight /></Link>
              </div>
             
            </div>
          )}
        </div>
        <div className="sidemenuBody">
            <ul>
                <li>
                    <NavLink to={`${path}/`} end className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbHome2 /> Home</NavLink>
                </li>
                {gettokenval &&  roleval=='candidate' && (
                <li>
                    <NavLink to={`${path}/savedJobs`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbBookmarks /> Saved Jobs</NavLink>
                </li>
                )}
                {gettokenval &&  roleval=='candidate' && (
                <li>
                    <NavLink to={`${path}/appliedJobs`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbBriefcase /> Applied Jobs</NavLink>
                </li>
                 )}
                <li>
                  <NavLink to={`${path}/jobs`} className={() => (isActiveJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbDeviceIpadHorizontalCheck /> Jobs</NavLink>
                </li>
            </ul>
            <ul>
                <li>
                  <NavLink to={`${path}/immigration`} className={() => (isCountriesJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><IoMdGlobe /> Immigration</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/about`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbBuilding /> About</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/service`} className={() => (isServiceJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbHeartHandshake /> Services</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/blog`} className={() => (isBlogJob() ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbMessage2Search /> Blogs</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/testimonials`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><TbMessage2Heart /> Testimonials</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/gallery`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><BiImages /> Gallery</NavLink>
                </li>
                <li>
                  <NavLink to={`${path}/contact`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSidemenuClick}><BiSupport /> Contact</NavLink>
                </li>
            </ul>
            {gettokenval &&  roleval=='candidate' && (
            <ul>
                <li>
                  <NavLink  onClick={handleLogout} className='logOutBtn'><TbLogout /> Logout</NavLink>
                </li>
            </ul>
            )}
        </div>
    </aside>

    
    {isLoginPopupVisible && (
      <div className="loginPopup">
          <div className="closeloginPopup" onClick={closeloginPopup}><GrClose /></div>
          <div className="loginPopupHead">
            <h2 className='mainTitle'>Hey, Welcome!</h2>
            <p>If you are in, we have the perfect solutions for you!</p>
          </div>
          <div className="loginPopupBody">
            <Link to={`${path}/login`} className='loginBtn' onClick={closeloginPopup}>Login <LuChevronRight /></Link>
            <Link to={`${path}/register`} className='registerBtn' onClick={closeloginPopup}>Register <LuChevronRight /></Link>
          </div>
      </div>
    )}
    
    </>
  );
}

export default Nav;
