import React, { useState } from "react";
import {Helmet} from "react-helmet";
import HomeBanner from "./homeBanner";
import HomeExploreJobs from "./homeExploreJobs";
import HomeAbout from "./homeAbout";
//import HomeJobCatagory from "./homeJobCatagory";
import HomeService from "./homeService";
import HomeBlog from "./homeBlog";
import HomeTestimonials from "./homeTestimonials";
import HomeGallery from "./homeGallery";
import HomeContact from "./homeContact";
import withAuth from '../session/withAuth';

function Home() {
  // State to hold the search parameters
  const [searchFilters, setSearchFilters] = useState({
    jobTitle: null,
    location: null,
    experience: null,
  });

  // Function to update search filters from HomeBanner
  const handleSearchFilters = (filters) => {
    setSearchFilters(filters);
    //console.log(filters)
  };

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Best Overseas Job Consultants in Kerala | Daniels Job Finder</title>
          <meta name="description" content="One of the leading overseas job consultants in Kerala. Daniels Job Finder provides assistance in getting work visas and finding a job. Start your career journey today.
"/>
          <link rel="canonical" href="https://danielsjobfinder.com/" />

      </Helmet>
      
      {/* Pass the handleSearchFilters function to HomeBanner */}
      <HomeBanner onSearch={handleSearchFilters} />

      {/* Pass the searchFilters to HomeExploreJobs */}
      <HomeExploreJobs searchFilters={searchFilters} setSearchFilters={setSearchFilters} />

      <HomeAbout />
      {/* <HomeJobCatagory /> */}
      <HomeService />
      <HomeBlog />
      <HomeTestimonials />
      <HomeGallery />
      <HomeContact />
    </>
  );
}

export default Home;
