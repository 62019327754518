import React, { useState, useEffect, Suspense, lazy } from "react";
import HomeContact from "../home/homeContact";
import BackBtn from "../settings/backBtn";
import { Link } from "react-router-dom";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router-dom'; // Import to get URL parameters
import { IoCallOutline } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';

import bannerImg1 from '../../assets/images/country/img1.jpg';
import companyLogo1 from '../../assets/images/compnay/logoIcon1.png';
import companyLogo2 from '../../assets/images/compnay/img1.webp';
import companyLogo3 from '../../assets/images/compnay/img2.svg';
import companyLogo4 from '../../assets/images/compnay/img3.png';
import companyLogo5 from '../../assets/images/compnay/img4.webp';
import companyLogo6 from '../../assets/images/compnay/img5.webp';

import serviceImg1 from '../../assets/images/service/img1.jpg';
import serviceImg2 from '../../assets/images/service/img2.jpg';
import serviceImg3 from '../../assets/images/service/img3.jpg';
import serviceImg4 from '../../assets/images/service/img4.jpg';
import serviceImg5 from '../../assets/images/service/img5.jpg';
import serviceImg6 from '../../assets/images/service/img6.jpg';
import serviceImg7 from '../../assets/images/service/img7.jpg';
import serviceImg8 from '../../assets/images/service/img8.jpg';
import serviceImg9 from '../../assets/images/service/img9.jpg';
import companyLogo7 from '../../assets/images/logo.png';
import emptyImg from '../../assets/images/emptyImg.jpg';
import axios from "axios";
import { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC';

const path = "";
const mediaPath = mediaUrl;
const JobBox = lazy(() => import("../home/jobBox"));
const HomeServiceBox = lazy(() => import("../home/homeServiceBox"));

function ImmigrationDetails() { // Accepting country ID as a prop
    const [countryName, setCountryName] = useState("");
    const [innerdescription, setinnerdescription] = useState("");
    const [serviceForms, setServiceForms] = useState([]);
    const [flagImage,setFlagImage] = useState('');
    const [tumb,setTumb] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const [jobData, setJobData] = useState([]);
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1.1,
                },
            },
        ],
    };

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axios.get(`${baseURL}/country/${id}`);
                const country = response.data.data;
                //alert(country)
                console.log(country); // Debugging the response
                setCountryName(country.country_name);
                setinnerdescription(country.inner_description)
                setServiceForms(country.services || []);
                setFlagImage(country.flag_image)
                setTumb(country.thumbnail_image)
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError(true);
                setLoading(false);
            }
        };

        fetchCountry();
    }, [id]);

    useEffect(() => {
        const login_id = localStorage.getItem('login_id');
        const fetched_country_based_job = async () => {
            try {
                const response = await axios.get(`${baseURL}/ftech_country_based_job/${id}?login_id=${login_id}`);
                const country = response.data;

                console.log(country); // Debugging thSe response
                setJobData(response.data)
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError(true);
                setLoading(false);
            }
        };

        fetched_country_based_job();
    }, [id]);

    if (loading) {
        return (<>
        <section id="countryDetailsBanner">
            <div className="container">
                <BackBtn />
                <div className="countryDetailsBannerMain">
                    <div className="countryDetailsBannerImg">
                        <img src={mediaPath+ "tumb"} alt="" />
                    </div>
                    <h1></h1>
                </div>
                <div className="countryDetailslist">
                       
                </div>
            </div>
        </section>

        <section id="immigrationList">
            <div className="container">
                <div className="immigrationListHead">
                    <h2 className="mainTitle">We specialize in immigration and we do it with excellence</h2>
                </div>
                <div className="immigrationListMain">
                    <Slider {...settings}>
                        
                            <div className="item" key={"index"}>
                                <HomeServiceBox 
                                    homeServiceBoxLink={"${path}/InnerImmigrationService/${service.id}"} 
                                    homeServiceBoxThumbnail={"mediaPath + service.service_image"} // Update with dynamic service thumbnail if available
                                    homeServiceBoxTitle={"service.service_title"} // Assuming title exists in the service object
                                    homeServiceBoxDesc={"service.service_description"} // Assuming description exists
                                />
                            </div>
                        
                    </Slider>
                </div>
            </div>
        </section>

        <section id="countryDetails">
            <div className="container">
                <h2 className="title">Related Jobs1</h2>
                <div className="countryDetailsMain">
                    <Suspense fallback={<div>Loading job details...</div>}>
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo1} jobCompanyName="Esight Business Solutions Pvt Ltd" jobTitle="Web Developer" jobLocation="Angamaly, Kerala" jobExperiance="2 - 5 Yrs" jobSalary="30k - 50k" jobOpening="2" jobPostedDate="1" />
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo2} jobCompanyName="Google Pvt Ltd" jobTitle="Google Cloud Consulting" jobLocation="UK" jobExperiance="1 - 2 Yrs" jobSalary="1 L - 1.5 L" jobOpening="1" jobPostedDate="1" />
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo3} jobCompanyName="Amazon" jobTitle="Truck Driver" jobLocation="Kerala, India" jobExperiance="5 - 8 Yrs" jobSalary="1 L - 2 L" jobOpening="3" jobPostedDate="3" />
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo4} jobCompanyName="Lulu Group International" jobTitle="Assistant Manager" jobLocation="Dubai" jobExperiance="1 - 2 Yrs" jobSalary="50k - 75k" jobOpening="1" jobPostedDate="2" />
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo5} jobCompanyName="Bayerische Motoren Werke AG" jobTitle="Sales Executive" jobLocation="USA" jobExperiance="3 - 4 Yrs" jobSalary="2 L - 3 L" jobOpening="5" jobPostedDate="3" />
                        <JobBox jobLink={`${path}/jobDetails`} jobCompanyImg={companyLogo6} jobCompanyName="X Corp" jobTitle="Prompt Engineer" jobLocation="California, US" jobExperiance="1 - 2 Yrs" jobSalary="75k - 1 L" jobOpening="10" jobPostedDate="4" />
                    </Suspense>
                </div>
            </div>
        </section>
        <HomeContact />
    </>);
    }

    

    return (
        <>
          
            <section id="countryDetailsBanner">
                <div className="container">
                    <BackBtn />
                    <div className="countryDetailsBannerMain">
                        <div className="countryDetailsBannerImg">
                            <img src={mediaPath + tumb} alt="" />
                        </div>
                        <h1>{countryName}</h1>
                    </div>
                    <div className="countryDetailslist ql-editor" dangerouslySetInnerHTML={{ __html: innerdescription }}>
                        
                    </div>
                </div>
            </section>

            <section id="immigrationList">
                <div className="container">
                    <div className="immigrationListHead">
                        <h2 className="mainTitle">We specialize in immigration and we do it with excellence</h2>
                    </div>
                    <div className="immigrationListMain">
                    {serviceForms.length > 0 ?(
                        <Slider {...settings}>
                            {serviceForms.map((service, index) => (
                                <div className="item" key={index}>
                                    <HomeServiceBox 
                                        homeServiceBoxLink={`${path}/InnerImmigrationService/${service.id}`} 
                                        homeServiceBoxThumbnail={mediaPath + service.service_image} // Update with dynamic service thumbnail if available
                                        homeServiceBoxTitle={service.service_title} // Assuming title exists in the service object
                                        homeServiceBoxDesc={service.service_description} // Assuming description exists
                                    />
                                </div>
                            ))}
                        </Slider>
                        ):(
                            <div className="noDataFound">
                            <div className="noDataFoundIcon">
                                <img src={emptyImg} alt="" />
                            </div>
                            <div className="noDataFoundDetails">
                                <h3>Opps !</h3>
                                <p>If you don’t find what you are looking for, call/WhatsApp us on 0091 94478 70999 and we will be happy to personally find a good fit for you.</p>
                                <Link to="tel:00919447870999" className="noDataFoundClearFIlterBtn">
                                <IoCallOutline /> Call Now
                                </Link>
                            </div>
                            </div>
                        )
                    }
                    </div>
                </div>
            </section>

            <section id="countryDetails">
                <div className="container">
                    <h2 className="title">Related Jobs</h2>
                    <div className="countryDetailsMain">
                    {loading ? (
                <>
                  <Suspense>
                    <JobBox jobLink='' jobCompanyImg='' jobCompanyName='' jobTitle='' jobLocation='' jobExperiance='' jobSalary='' jobOpening='' jobPostedDate='' applyJobLink='' Shortdescription='' />
                  </Suspense>
                </>
              ) : jobData.length > 0 ? (
                jobData.map((job, index) => (
                  <Suspense key={index}>
                    <JobBox
                      jobLink={`${path}/jobDetails/${job.id}`}
                      jobCompanyImg={companyLogo7}
                      jobCompanyName={job.company_name}
                      jobTitle={job.job_title}
                      jobLocation={job.location_val}
                      jobExperiance={job.experience_range}
                      jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}
                      jobOpening={job.no_openings}
                      jobPostedDate={job.days_ago}
                      applyJobLink={`${path}/applyJobForm/${job.id}`}
                      Shortdescription={job.short_description}
                      jobidval={job.id}
                      savedstatus={job.savedstatus}
                      applystatus={job.apply_status}
                    />
                  </Suspense>
                ))
              ) : (
                <div className="noDataFound">
                  <div className="noDataFoundIcon">
                    <img src={emptyImg} alt="" />
                  </div>
                  <div className="noDataFoundDetails">
                    <h3>Opps !</h3>
                    <p>If you don’t find what you are looking for, call/WhatsApp us on 0091 94478 70999 and we will be happy to personally find a good fit for you.</p>
                    <Link to="tel:00919447870999" className="noDataFoundClearFIlterBtn">
                      <IoCallOutline /> Call Now
                    </Link>
                  </div>
                </div>
              )}
                    </div>
                </div>
            </section>
            <HomeContact />
        </>
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button className={className} style={{ ...style, display: "block" }} onClick={onClick}>
            <GoArrowRight />
        </button>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button className={className} style={{ ...style, display: "block" }} onClick={onClick}>
            <GoArrowLeft />
        </button>
    );
}

export default ImmigrationDetails;
