import React, { useState, forwardRef, useImperativeHandle,useEffect } from 'react';
import { HiOutlineChevronDown } from "react-icons/hi2";
import { VscClose } from "react-icons/vsc";
import Select from "react-select";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import axios from 'axios';
import { useLocation } from 'react-router-dom';



const JobFilter = forwardRef(({ applyFilters}, ref) => {
    const [filters, setFilters] = useState({
        selectedKeywords: [],
        selectedJobTitles: [],
        selectedLocations: [],
        selectedWorkEnvironment: [],
        // Other filter states if necessary
      });
    const [isClearable] = useState(true);
    const location = useLocation();
    const [openDropdowns, setOpenDropdowns] = useState({
      keyword: false,
      position: false,
      jobTitle: false,
      location: false,
      sort: false,
      jobType: false,
      workEnvironment: false,
    });

    useImperativeHandle(ref, () => ({
        toggleDropdown(name) {
          setOpenDropdowns((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
          }));
        },
    }));
    useEffect(() => {
        clearFilters();
      }, [location]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [selectedJobTitles, setSelectedJobTitles] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedSort, setSelectedSort] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState([]);
    const [keywordItem, setkeywordItem] = useState([]);
    const [jobTitles, setjobTitles] = useState([]);
    const [locations, setlocations] = useState([]);
    const [positions, setpositions] = useState([]);
    const [selectedWorkEnvironment, setSelectedWorkEnvironment] = useState([]);

    const toggleDropdown = (name) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    useEffect(() => {
        axios.get(`${baseURL}/fetch_all_tags`) // Replace with your API URL
          .then(response => {
            //console.log(response.data)
            const fromattedtags = response.data.map(tags => ({
                value: tags.id,
                label: tags.tag_val 
              }));
            setkeywordItem(fromattedtags);
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      }, []); 

      //job title section
      useEffect(() => {
        axios.get(`${baseURL}/fetch_all_job_titles`) // Replace with your API URL
          .then(response => {
            //console.log(response.data)
            const fromattedtags = response.data.map(jobtitle => ({
                value: jobtitle.job_title,
                label: jobtitle.job_title 
              }));
              setjobTitles(fromattedtags);
          })
          .catch(error => {
            console.error('Error fetching items:', error);
          });
      }, []); 

      //location section
    useEffect(() => {
        axios.get(`${baseURL}/fetch_all_location`) // Replace with your API URL
            .then(response => {
            //console.log(response.data)
            const fromattedtags = response.data.map(location_v => ({
                value: location_v.location_val,
                label: location_v.location_val 
                }));
                setlocations(fromattedtags);
            })
            .catch(error => {
            console.error('Error fetching items:', error);
            });
        }, []);

        const workEnvironmentItems = [
            { value: "Hybrid", label: "Hybrid" },
            { value: "On site", label: "On site" },
            { value: "Remote", label: "Remote" },
        ];
    

    

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'none', // Hides selected items from the dropdown
        }),
    };

    const handleSelectChange = (name, selectedOption) => {
    
        setFilters((prevFilters) => ({
            ...prevFilters, // Keep the previous state
            [name]: selectedOption || [], // Dynamically set the filter based on the name
        }));
        switch(name) {
            case 'keyword':
                setSelectedKeywords(selectedOption || []);
                break;
            case 'position':
                setSelectedPositions(selectedOption || []);
                break;
            case 'jobTitle':
                setSelectedJobTitles(selectedOption || []);
                break;
            case 'location':
                setSelectedLocations(selectedOption || []);
                break;
            case 'sort':
                setSelectedSort(selectedOption || []);
                break;
            case 'jobType':
                setSelectedJobType(selectedOption || []);
                break;
            case 'workEnvironment':
                setSelectedWorkEnvironment(selectedOption || []);
            default:
                break;
        }
    };

    const removeSelectedItem = (name, value) => {
        let filteredItems = [];
    
        switch (name) {
            case 'keyword':
                filteredItems = selectedKeywords.filter(item => item.value !== value);
                setSelectedKeywords(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    keyword: filteredItems
                }));
                break;
            case 'position':
                filteredItems = selectedPositions.filter(item => item.value !== value);
                setSelectedPositions(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    position: filteredItems
                }));
                break;
            case 'jobTitle':
                filteredItems = selectedJobTitles.filter(item => item.value !== value);
                setSelectedJobTitles(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    jobTitle: filteredItems
                }));
                break;
            case 'location':
                filteredItems = selectedLocations.filter(item => item.value !== value);
                setSelectedLocations(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    location: filteredItems
                }));
                break;
            case 'sort':
                filteredItems = selectedSort.filter(item => item.value !== value);
                setSelectedSort(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    sort: filteredItems
                }));
                break;
            case 'jobType':
                filteredItems = selectedJobType.filter(item => item.value !== value);
                setSelectedJobType(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    jobType: filteredItems
                }));
                break;
            case 'workEnvironment':
                filteredItems = selectedWorkEnvironment.filter(item => item.value !== value);
                setSelectedWorkEnvironment(filteredItems);
                setFilters(prevFilters => ({
                    ...prevFilters,
                    workEnvironment: filteredItems
                }));
                break;
            default:
                break;
        }
    
        console.log(filteredItems, "filtered items");
    };

    const renderSelectedItems = (selectedItems, name) => (
        selectedItems.map(item => (
            <div key={item.value} className="selectedItem">
                {item.label}
                <span className="removeItemBtn" onClick={() => removeSelectedItem(name, item.value)}>
                    <VscClose />
                </span>
            </div>
        ))
    );

    const clearFilters = () => {
        // Reset all states to their default values
        setFilters({
            selectedKeywords: [],
            selectedJobTitles: [],
            selectedLocations: [],
            selectedWorkEnvironment: [],
            selectedPositions : [],
            selectedSort : [],
            selectedJobType : [],
            // Add other default filter states if needed
        });
    
        setSelectedKeywords([]);
        setSelectedPositions([]);
        setSelectedJobTitles([]);
        setSelectedLocations([]);
        setSelectedSort([]);
        setSelectedJobType([]);
        setSelectedWorkEnvironment([]);
        setOpenDropdowns({
            keyword: false,
            position: false,
            jobTitle: false,
            location: false,
            sort: false,
            jobType: false,
            workEnvironment: false,
        });
    
        // Pass the cleared filters back to the parent component
        applyFilters({
            selectedKeywords: [],
            selectedJobTitles: [],
            selectedLocations: [],
            selectedWorkEnvironment: [],
            selectedPositions : [],
            selectedSort : [],
            selectedJobType : [],
        });
        console.log("Filters cleared:", filters);

    };
    //console.log(selectedKeywords)

    const closeFilterClick = () => {
        const jobWraperFilter = document.querySelector('.jobWraperFilter');
        const shimmer = document.querySelector('.shimmer');
        const body = document.querySelector('body');
    
        if (jobWraperFilter) {
          jobWraperFilter.style.left = '-110%';
        }
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
        setOpenDropdowns({
            keyword: false,
            position: false,
            jobTitle: false,
            location: false,
            sort: false,
            jobType: false,
        });
    };

    const handleApply = () => {
        const jobWraperFilter = document.querySelector('.jobWraperFilter');
        const shimmer = document.querySelector('.shimmer');
        const body = document.querySelector('body');
    
        if (jobWraperFilter) {
          jobWraperFilter.style.left = '-110%';
        }
        if (shimmer) {
            shimmer.style.display = 'none';
        }
        if (body) {
            body.style.overflow = 'inherit';
        }
        console.log(filters)
        applyFilters(filters)
        window.scrollTo(0, 0);
       // fetchJobs(0); // Call fetchJobs with the offset 0 to get new data
    };

    return (
        <>
            <div className="jobWraperFilter">
                <div className="jobWraperFilterFooter">
                    <div className="clearFilterBtn" onClick={clearFilters}>Clear Filter</div>
                    <div className="applyFilterBtn"onClick={handleApply} >Apply Filter</div>
                    <div className="mobileApplyFilterBtn"  onClick={handleApply}>Apply Filter</div>
                </div>
                <div className="jobWraperFilterHead">
                    <div className="jobWraperFilterHeadCol1">
                        <h2>Filters</h2>
                        {/* <p>Add preferences to get matching jobs</p> */}
                    </div>
                    <div className="jobWraperFilterHeadCol2">
                        <div className="closeFilterBtn" onClick={closeFilterClick}><VscClose /></div>
                    </div>
                </div>
                <div className="jobWraperFilterBody">
                    <div className="jobWraperFilterForm">
                        {/* Search by Keyword */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('keyword')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Skills</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.keyword ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.keyword && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedKeywords, 'keyword')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={keywordItem}
                                            value={selectedKeywords} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('keyword', selected)}
                                            placeholder="Skills"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Search by Position Names */}
                        {/* <div className="formGroup"> */}
                            {/* <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('position')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Position Names</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.position ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div> */}
                            {/* {openDropdowns.position && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedPositions, 'position')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={positions}
                                            value={selectedPositions} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('position', selected)}
                                            placeholder="Positions"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )} */}
                        {/* </div> */}

                        {/* Job Title */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('jobTitle')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Job Title</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.jobTitle ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.jobTitle && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedJobTitles, 'jobTitle')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={jobTitles}
                                            value={selectedJobTitles} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('jobTitle', selected)}
                                            placeholder="Job Titles"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Location */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('location')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Search by Location</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.location ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.location && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedLocations, 'location')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={locations}
                                            value={selectedLocations} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('location', selected)}
                                            placeholder="Locations"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Work environment */}
                        <div className="formGroup">
                            <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('workEnvironment')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Work Environment</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.workEnvironment ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.workEnvironment && (
                                <div className="formGroupBody">
                                    <div className="formGroupSelect">
                                        <div className="dorpDOwnSelectedItemsAera">
                                            {renderSelectedItems(selectedWorkEnvironment, 'workEnvironment')}
                                        </div>
                                        <Select
                                            isMulti
                                            options={workEnvironmentItems}
                                            value={selectedWorkEnvironment} // Keeps track of selected items
                                            onChange={(selected) => handleSelectChange('workEnvironment', selected)}
                                            placeholder="Work Environment"
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            backspaceRemovesValue={false}
                                        />
                                    </div>


                                </div>
                            )}
                        </div>

                        {/* Sort */}
                        <div className="formGroup" style={{display: 'none'}}>
                             <div
                                className="formGroupHead"
                                onClick={() => toggleDropdown('sort')}
                            >
                                <div className="formGroupHeadCol1">
                                    <h3 className="formTitle">Sort By</h3>
                                </div>
                                <div className="formGroupHeadCol2">
                                    <div
                                        className={`filterFormDropDownBtn ${
                                            openDropdowns.sort ? 'rotate-180' : ''
                                        }`}
                                    >
                                        <HiOutlineChevronDown />
                                    </div>
                                </div>
                            </div>
                            {openDropdowns.sort && (
                                <div className="formGroupBody">
                                    <div className="formGroupRadioArea">
                                        <ul>
                                            <li>
                                                <input type="radio" id='relevance' name='sort' />
                                                <label htmlFor="relevance">
                                                    <div className="radioIcon"></div>
                                                    <div className="radioText">Relevance</div>
                                                </label>
                                            </li>
                                            <li>
                                                <input type="radio" id='date' name='sort' />
                                                <label htmlFor="date">
                                                    <div className="radioIcon"></div>
                                                    <div className="radioText">Date</div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div> 

                    </div>
                </div>
            </div>
        </>
    );
})

export default JobFilter;
