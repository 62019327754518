import React, { useState,useEffect } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";
import axios from 'axios';
import Select from "react-select";
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';


const monthofBirth = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const dateofBirth = Array.from({ length: 31 }, (_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }));
const yearofBirth = Array.from({ length: 65 }, (_, i) => ({ value: (2024 - i).toString(), label: (2024 - i).toString() }));


const proficiency = [
  { value: "Beginner", label: "Beginner" },
  { value: "Proficient", label: "Proficient" },
  { value: "Expert", label: "Expert" },
];

function PersonalDetailsForm({ formDataperson, setFormDataperson,setPersonalErrors,personalErrors }) {
  const [isClearable] = useState(true);
  const [loginId,setLoginId] = useState(1);
  const [activeIndex, setActiveIndex] = useState(null);
  const [languages, setLanguages] = useState([
    { id: Date.now(), language_val: '', proficiancy_val: '',  read_status: false, write_status: false, speak_status: false  }
  ]);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 100,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
      color: state.isSelected ? '#000' : 'inherit',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000',
    }),
  };

  const toggleSection = (index) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  




  const deleteLanguage = async (id, lang_id) => {
    // Filter the languages
    const updatedLanguages = languages.filter(language => language.id !== id);
  
    // Update the languages state
    setLanguages(updatedLanguages);
  
    // Update the formDataperson state to reflect the changes
    setFormDataperson(prevData => ({
      ...prevData,
      language: updatedLanguages
    }));
  
    // Make API call to delete the language
    try {
      const response = await axios.get(`${baseURL}/deleteLanguage/${lang_id}`);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  

  const addLanguage = () => {
    const newLanguage = {
      id: Date.now(),
      language_val: '',
      proficiancy_val: '',
  
      read_status: false,
      write_status: false,
      speak_status: false,
      
  };
    
    // console.log('Adding language:', newLanguage);
    setLanguages(prevLanguages => [...prevLanguages, newLanguage]);
  };
  
  useEffect(() => {
    const storedOtpCode = localStorage.getItem('otp_code');
    const storeloginid = localStorage.getItem('login_id');
    const storeemailval = localStorage.getItem('emailval');
    const stcandidate_name = localStorage.getItem('candidate_name');

    
    setLoginId(storeloginid)
   
}, []);
  const handleInputChange = (id, e) => {
    const { name, value } = e.target;
  
    setLanguages(prevLanguages => {
      const updatedLanguages = prevLanguages.map(language => 
        language.id === id ? { ...language, [name]: value } : language
      );
  
      // Update formDataperson as well
      setFormDataperson(prevData => ({
        ...prevData,
        language: updatedLanguages
      }));
  
      return updatedLanguages;
    });
  };
  
  






  const handleSkillChange = (id, skill) => {
    const updatedLanguages = languages.map(language =>
      language.id === id ? { 
        ...language, 
        [skill]: !language[skill]  // Toggle the specific skill
      } : language
    );
  
    setLanguages(updatedLanguages);
  
    // After updating languages, also update formDataperson with the updated languages
    setFormDataperson(prevData => ({
      ...prevData,
      language: updatedLanguages
    }));
  };
  
  //console.log(formDataperson)
  



  // const handleInputChange = (id, field, value) => {
  //   setFormDataperson((prevLanguages) =>
  //     prevLanguages.map((language) =>
  //       language.id === id ? { ...language, [field]: value } : language
  //     )
  //   );
  // };

  // const handleSkillChange = (id, skill) => {
  //   setFormDataperson((prevLanguages) =>
  //     prevLanguages.map((language) =>
  //       language.id === id
  //         ? {
  //             ...language,
  //             skills: { ...language.skills, [skill]: !language.skills[skill] },
  //           }
  //         : language
  //     )
  //   );
  // };


  const handleGenderChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      gender_val: e.target.value,
    }));
  };

  
  const handlemaritalChange = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      marital_status: e.target.value,
    }));
  };
  
  const handleDateOfBirthChange = (selectedOption) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      day_val: selectedOption ? selectedOption.value : '', // Handle null case if cleared
    }));
};

const handleMonthOfBirthChange = (selectedOption) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      month_val: selectedOption ? selectedOption.value : '',
    }));
};

const handleYearOfBirthChange = (selectedOption) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      year_val: selectedOption ? selectedOption.value : '',
    }));
};


  const handlechangeadress = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      permenent_adress: e.target.value,
    }));
  };
  const handlechangehometown = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      home_town: e.target.value,
    }));
  };
  const handlechangepincode = (e) => {
    setFormDataperson((prevData) => ({
      ...prevData,
      pincode_val: e.target.value,
    }));
  };
  useEffect(() => {
    const fetchPersonalDetails = async () => {
        try {
            const storeloginid = await localStorage.getItem('login_id');
            const response = await axios.get(`${baseURL}/getPersonalDetails/${storeloginid}`);
            console.log(response)
            if (response.data.status === 'success') {
                const data = response.data.data;

                setFormDataperson({
                    gender_val: data.gender_val || '',
                    marital_status: data.marital_status || '',
                    day_val: data.day_val || '',
                    month_val: data.month_val || '',
                    year_val: data.year_val || '',
                    permenent_adress: data.permenent_adress || '',
                    home_town: data.home_town || '',
                    pincode_val: data.pincode_val || '',
                    language: data.language || [], // Assuming this is an array
                });

                // Set the languages if they are present
                if (data.language && Array.isArray(data.language)) {
                    setLanguages([]);
                    const languagesData = data.language.map((lang, index) => ({
                        id: `${Date.now()}-${index}`,  // Unique ID with timestamp and index
                        language_val: lang.language_val || '',
                        lang_id : lang.id || 0,
                        proficiancy_val: lang.proficiancy_val || '',
                        read_status: lang.read_status === '1',
                        write_status: lang.write_status === '1',
                        speak_status: lang.speak_status === '1',
                    }));
                    setLanguages(languagesData);
                }
            } else {
                // Handle error if needed
            }
        } catch (err) {
            console.log(err);
            // Handle error if needed
        }
    };

    fetchPersonalDetails();
}, [loginId]);

  return (
    <>
      <div className="personalDetailsForm">
        <div className="formGroup col-1">
          <label htmlFor="">Gender</label>
          <div className="formRadioGroupButton">
          {['male', 'female', 'other'].map(gender => (
            <div className="formRadioGroupBoxButton" key={gender}>
              <input
                type="radio"
                id={gender}
                name="gender"
                value={gender}
                checked={formDataperson.gender_val === gender}
                onChange={handleGenderChange}
              />
              <label htmlFor={gender}>{gender.charAt(0).toUpperCase() + gender.slice(1)}</label>
            </div>
          ))}
          </div>
          {personalErrors.gender && <span className="error">{personalErrors.gender}</span>}
      </div>

        <div className="formGroup col-1">
          <label htmlFor="">Marital status</label>
          <div className="formRadioGroupButton">
          {['single/unmarried', 'married', 'widowed', 'divorced', 'separated', 'other'].map(status => (
            <div className="formRadioGroupBoxButton" key={status+'1'}>
              <input
                type="radio"
                id={status+'1'}
                name="maritalStatus"
                value={status}
                checked={formDataperson.marital_status === status}
                onChange={handlemaritalChange}
              />
              <label htmlFor={status+'1'}>{status.charAt(0).toUpperCase() + status.slice(1)}</label>
            </div>
          ))}
          </div>
          {personalErrors.maritalStatus && <span className="error">{personalErrors.maritalStatus}</span>}
        </div>
        <div className="formGroup col-1">
          <label htmlFor="">Date of birth</label>
          <div className="formGroupDorpdownList">
            <div className="formGroupDorpdownListBox dorpdownList-Col-3">
              <Select
                options={dateofBirth}
                placeholder="Select Date"
                isSearchable={true}
                className="customSelect"
                isClearable={isClearable}
                styles={customStyles}
                onChange={handleDateOfBirthChange}
                value={dateofBirth.find(option => option.value === formDataperson.day_val)}
              />
            </div>
            <div className="formGroupDorpdownListBox dorpdownList-Col-3">
              <Select
                options={monthofBirth}
                placeholder="Select Month"
                isSearchable={true}
                className="customSelect"
                isClearable={isClearable}
                styles={customStyles}
                onChange={handleMonthOfBirthChange}
                value={monthofBirth.find(option => option.value === formDataperson.month_val)}
              />
            </div>
            <div className="formGroupDorpdownListBox dorpdownList-Col-3">
              <Select
                options={yearofBirth}
                placeholder="Select Year"
                isSearchable={true}
                className="customSelect"
                isClearable={isClearable}
                styles={customStyles}
                onChange={handleYearOfBirthChange}
                value={yearofBirth.find(option => option.value === formDataperson.year_val)}
              />
            </div>
          </div>
          {personalErrors.dateOfBirth && <span className="error">{personalErrors.dateOfBirth}</span>}
        </div>
        <div className="formGroup col-1">
          <label htmlFor="">Permanent address</label>
          <textarea name="permenentadress" value={formDataperson.permenent_adress} onChange={handlechangeadress}></textarea>
          {personalErrors.permenentadress && <span className="error">{personalErrors.permenentadress}</span>}
        </div>
        <div className="formGroup">
          <label htmlFor="">Home Town</label>
          <input type="text" name="hometown" value={formDataperson.home_town} onChange={handlechangehometown}/>
          {personalErrors.hometown && <span className="error">{personalErrors.permenentadress}</span>}
        </div>
        <div className="formGroup">
          <label htmlFor="">Pincode</label>
          <input type="text" name="pincode"value={formDataperson.pincode_val} onChange={handlechangepincode}/>
          {personalErrors.pincode && <span className="error">{personalErrors.pincode}</span>}
        </div>
      </div>

      <div className="eductionForm">
        <div className="eductionFormBox">
          <div className="eductionFormBoxHead" onClick={() => toggleSection(0)}>
            <h3>Language proficiency</h3>
            <IoChevronDownOutline />
          </div>
          <div className={`eductionFormBoxBody ${activeIndex === 0 ? 'eductionFormBoxBodyActive' : ''}`}>
            <div className="languageDetailsForm">
              
              
            {languages.map((language, index) => (
              
                <div className="languageDetailsFormBox" key={language.id}>
                  {/* {console.log(languages)} */}
                  <div className="formGroup">
                    <label htmlFor="">Language</label>
                    <input
                      type="text"
                      name="language_val"
                      value={language.language_val}
                      onChange={(e) => handleInputChange(language.id, e)} // Pass the event directly
                    />
                  </div>

                  <div className="formGroup">
                    <label htmlFor="">Proficiency</label>
                    <div className="formGroupDorpdownList">
                      <div className="formGroupDorpdownListBox">
                        <Select
                          options={proficiency}
                          placeholder="Proficiency"
                          isSearchable={true}
                          className="customSelect"
                          isClearable={isClearable}
                          styles={customStyles}
                          value={proficiency.find(option => option.value === language.proficiancy_val)}
                          onChange={(selectedOption) => {
                            handleInputChange(language.id, {
                              target: {
                                name: 'proficiancy_val',
                                value: selectedOption ? selectedOption.value : '', // Ensure to handle null case
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="languageDetailsFormBoxFooter">
                    <div className="languageDetailsFormBoxFooterCol1">
                      <div className="formGroupCheckboxArea">
                        <ul>
                          <li>
                            <input
                              type="checkbox"
                              id={`read${language.id}`}
                              checked={language.read_status}
                              onChange={() => handleSkillChange(language.id, 'read_status')}
                            />
                            <label htmlFor={`read${language.id}`}>
                              <div className="checkboxIcon"></div>
                              <div className="checkboxText">Read</div>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id={`write${language.id}`}
                              checked={language.write_status}
                              onChange={() => handleSkillChange(language.id, 'write_status')}
                            />
                            <label htmlFor={`write${language.id}`}>
                              <div className="checkboxIcon"></div>
                              <div className="checkboxText">Write</div>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id={`speak${language.id}`}
                              checked={language.speak_status}
                              onChange={() => handleSkillChange(language.id, 'speak_status')}
                            />
                            <label htmlFor={`speak${language.id}`}>
                              <div className="checkboxIcon"></div>
                              <div className="checkboxText">Speak</div>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* {index === 0 && personalErrors.languages && (
                        <span className="error">{personalErrors.languages}</span>
                    )} */}
                    <div className="languageDetailsFormBoxFooterCol2">
                      {index !== 0 && (
                        <div className="deleteLanguageBtn" onClick={() => deleteLanguage(language.id,language.lang_id || 0)}>
                          Delete
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}


              <div className="addLanguageDetailsFormBoxArea">
                <div className="addLanguageBtn" onClick={addLanguage}>Add another language</div>
              </div>
            </div>
          </div>
          {personalErrors.languages &&
          <span className="error">{personalErrors.languages}</span>
          }
        </div>
      </div>
    </>
  );
}

export default PersonalDetailsForm;
