import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import WindowedSelect from "react-windowed-select"; // Virtualized select dropdown
import { Link } from "react-router-dom";
// import bannerVideo1 from 'https://sreenagarajatemple.in/danielsJobsAssets-14-11-2024/video1.mp4';
// import bannerVideo2 from 'https://sreenagarajatemple.in/danielsJobsAssets-14-11-2024/video2.mp4';
// import bannerVideo3 from 'https://sreenagarajatemple.in/danielsJobsAssets-14-11-2024/video3.mp4'; // Add more videos here
import axios from 'axios';
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
const bannerVideo1 = 'https://sreenagarajatemple.in/danielsJobsAssets-14-11-2024/video1.mp4';
const bannerVideo2 = 'https://sreenagarajatemple.in/danielsJobsAssets-14-11-2024/video2.mp4';
const bannerVideo3 = 'https://sreenagarajatemple.in/danielsJobsAssets-14-11-2024/video3.mp4';

const experiences = [
    { value: "0-1", label: "0-1 Yrs" },
    { value: "1-2", label: "1-2 Yrs" },
    { value: "2-3", label: "2-3 Yrs" },
    { value: "3-4", label: "3-4 Yrs" },
    { value: "4-5", label: "4-5 Yrs" },
    { value: "5-6", label: "5-6 Yrs" },
    { value: "6-7", label: "6-7 Yrs" },
    { value: "7-8", label: "7-8 Yrs" },
    { value: "8-9", label: "8-9 Yrs" },
    { value: "9-10", label: "9-10 Yrs" },
    { value: "10-11", label: "10-11 Yrs" },
    { value: "11-12", label: "11-12 Yrs" },
    { value: "12-13", label: "12-13 Yrs" },
    { value: "13-14", label: "13-14 Yrs" },
    { value: "14-15", label: "14-15 Yrs" },
    { value: "15-16", label: "15-16 Yrs" },
    { value: "16-17", label: "16-17 Yrs" },
    { value: "17-18", label: "17-18 Yrs" },
    { value: "18-19", label: "18-19 Yrs" },
    { value: "19-20", label: "19-20 Yrs" },
    { value: "20+", label: "20+ Yrs" }
];

function HomeBanner({ onSearch }) {
    const [isClearable] = useState(true);
    const [currentVideo, setCurrentVideo] = useState(0); // Track current video index
    const [fade, setFade] = useState(true); // Track the fade effect
    // const [videoSources, setVideoSources] = useState([null, null, null]); // Lazy load videos
    const [videoSources, setVideoSources] = useState([bannerVideo1, null, null]);


    const slideInterval = 5000; // Auto-slide interval in milliseconds (5 seconds)
    const [jobTitles, setJobTitles] = useState([]);
    const [locations, setLocations] = useState([]);

    const headlines = [
        "Launch Your Dream Career in Canada!",
        "Boost Your Career in Germany’s Thriving Economy!",
        "Shape Your Future with Top Jobs in the UK!"
    ];

    const descriptions = [
        "Canada’s booming industries are calling! Find your perfect role and start thriving in one of the world’s most dynamic professional landscapes.",
        "Join Germany’s flourishing industries and unlock endless growth potential. Your next role is just a click away!",
        "From finance to tech, the UK offers unmatched opportunities. Discover your next big career leap today!"
    ];

    const [applyLinks, setApplyLinks] = useState([]);

    // Lazy load first video on mount
    useEffect(() => {
        setVideoSources([bannerVideo1, null, null]);
    }, []);

    // Fetch job titles and locations once on mount
    useEffect(() => {
        const fetchDataOnce = async (url, setterFunction, key) => {
            try {
                const response = await axios.get(`${baseURL}${url}`);
                setterFunction(response.data.map(item => ({
                    value: item[key],
                    label: item[key]
                })));
            } catch (error) {
                console.error(`Error fetching data from ${url}`, error);
            }
        };

        fetchDataOnce('/fetch_all_job_titles', setJobTitles, 'job_title');
        fetchDataOnce('/fetch_all_location', setLocations, 'location_val');
    }, []);

    // Fetch apply links once on mount
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${baseURL}/fetch_service_country`);
                setApplyLinks(response.data.data.map((x) => '/immigrationDetails/' + x.id));
            } catch (err) {
                console.error(err);
            }
        };
        fetchCountries();
    }, []);

    // Function to handle video change
    const handleNextVideo = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentVideo((prev) => {
                const nextVideo = (prev + 1) % 3;

                // Lazy-load the next video source if not loaded
                if (!videoSources[nextVideo]) {
                    const updatedSources = [...videoSources];
                    updatedSources[nextVideo] = [bannerVideo2, bannerVideo3][nextVideo - 1];
                    setVideoSources(updatedSources);
                }

                return nextVideo;
            });
            setFade(true);
        }, 200);
    };

    // Auto-slide effect using useEffect hook
    useEffect(() => {
        const autoSlide = setInterval(handleNextVideo, slideInterval);

        // Cleanup the interval on unmount
        return () => clearInterval(autoSlide);
    }, []);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    const [filters, setFilters] = useState({
        jobTitle: null,
        location: null,
        experience: null,
    });

    const handleSelectChange = (name, selectedOption) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: selectedOption ? selectedOption.value : null,
        }));
    };

    const searchBtnclick = (e) => {
        e.preventDefault();

        // Check if required fields are selected
        // if (!filters.jobTitle) {
        //     alert('Please select Job Title before searching.');
        //     return;
        // }
        onSearch(filters);
        window.scrollTo(0, 300)
    };

    return (
        <section id="homeBanner">
            <div className="homeBannerSlider">
                <div className={`homeBannerSliderBox ${fade ? 'fade-in' : 'fade-out'}`}>
                    <video 
                        key={currentVideo}
                        autoPlay 
                        loop 
                        muted 
                        playsInline 
                        data-wf-ignore="true" 
                        data-object-fit="cover" 
                        preload="auto" 
                        loading="lazy"
                    >
                        <source src={videoSources[currentVideo]} />
                    </video>
                </div>
            </div>
            <div className="container">
                <div className="homeBannerMain">
                    <h1>{headlines[currentVideo]}</h1>
                    <p>{descriptions[currentVideo]}</p>
                    <Link to={applyLinks[currentVideo]} className="bannerApplyBtn">Apply Now</Link>
                    <div className="bannerForm">
                        <form action="">
                            <div className="formGroupBox">
                                <div className="formGroup">
                                    <WindowedSelect
                                        options={jobTitles}
                                        placeholder="Job Title"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        onChange={(selected) => handleSelectChange('jobTitle', selected)}
                                    />
                                </div>
                                <div className="formGroup">
                                    <WindowedSelect
                                        options={locations}
                                        placeholder="Location"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        onChange={(selected) => handleSelectChange('location', selected)}
                                    />
                                </div>
                                <div className="formGroup">
                                    <WindowedSelect
                                        options={experiences}
                                        placeholder="Experience"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        onChange={(selected) => handleSelectChange('experience', selected)}
                                    />
                                </div>
                            </div>
                            <div className="formBtnArea">
                                <button className="formSbtBtn" onClick={searchBtnclick}>
                                    <FiSearch /> Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
