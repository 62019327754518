import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { lazy } from 'react';
import { GoChevronRight } from "react-icons/go";
import axios from 'axios';
import api, { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC';
import GalleryImg1 from '../../assets/images/gallery/img1.jpg'
import GalleryImg2 from '../../assets/images/gallery/img5.jpg'
import GalleryImg3 from '../../assets/images/gallery/img3.jpg'
import GalleryImg4 from '../../assets/images/gallery/img4.jpg'

const path = "";

const HomeGalleryBox = lazy(() => import("./homeGalleryBox"));

function HomeGallery() {
    const [imagesArray,setImagesArray] = useState([]);
    useEffect(() => {
        const fetchGalleryImages = async () => {
            try {
            const response = await api.get(`${baseURL}/gallery-list`); // Replace with actual API endpoint
            // Assuming the response data is an array of image URLs
            console.log(response)
            const images = response.data.data.map(img => ({ src: mediaUrl+img.gallery_image }));
            setImagesArray(images);
            } catch (error) {
            console.log(error)
            
            } finally {
            //setLoading(false);
            }
        };

        fetchGalleryImages();
    }, []);

    return (
        <section id="homeGallery">
            <HomeGalleryBox homeGalleryImg={GalleryImg1} />
            <HomeGalleryBox homeGalleryImg={GalleryImg2} />
            <HomeGalleryBox homeGalleryImg={GalleryImg3} />
            <HomeGalleryBox homeGalleryImg={GalleryImg4} />
            <div className="homeGalleryMain">
                <div className="container">
                    <div className="homeGalleryMainDetails">
                        <h3 className="mainTitle">Explore Our Collection, Moments Captured in Time</h3>
                        <Link to={`${path}/gallery`}>View More <GoChevronRight /></Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeGallery;
