import React, { useState } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
function EducationForm({ education, setEducation, educationError, setEducationError }) {
    const [eightStand, setEightStand] = useState('');
    const [tenthStand, setTenthStand] = useState('');
    const [twelvethStand, setTwelvethStand] = useState('');
    const [studyCollage, setStudyCollage] = useState('');
    const [completionDate8th, setCompletionDate8th] = useState(null);
    const [completionDate10th, setCompletionDate10th] = useState(null);
    const [completionDate12th, setCompletionDate12th] = useState(null);
    // const [completionDateBachelorsDegree, setCompletionDateBachelorsDegree] = useState(null);
    // const [completionDateMastersDegree, setCompletionDateMastersDegree] = useState(null);
    // const [completionDatePhdDoctorate, setCompletionDatePhdDoctorate] = useState(null);

    const [bachelorsCompleted, setBachelorsCompleted] = useState(null);
    const [bachelorsCompleted1, setBachelorsCompleted1] = useState(null);
    const [completionDateBachelors, setCompletionDateBachelors] = useState(null);
    const [mastersCompleted, setMastersCompleted] = useState(null);
    const [mastersCompleted1, setMastersCompleted1] = useState(null);
    const [completionDateMasters, setCompletionDateMasters] = useState(null);
    const [phdCompleted, setPhdCompleted] = useState(null);
    const [phdCompleted1, setPhdCompleted1] = useState(null);
    const [completionDatePhd, setCompletionDatePhd] = useState(null);

    const handleChangeInput = (value, name) => {
        setEducation(prevEducation => ({
            ...prevEducation,
            [name]: value
        }));
        //console.log(education)
    };
    
    

    const handleTenthStandStatusChange = (event) => {
        setTenthStand(event.target.id); // 'fresher' or 'experience'
    };

    const handleTwelvethStandStatusChange = (event) => {
        setTwelvethStand(event.target.id); // 'fresher' or 'experience'
    };

    const handlestudyCollageStatusChange = (event) => {
        setStudyCollage(event.target.id); // 'fresher' or 'experience'
    };

    const handleCompletedChange = (degreeType, value) => {
        switch (degreeType) {
          case 'bachelors':
            setBachelorsCompleted(value);
            break;
        case 'bachelors1':
            setBachelorsCompleted1(value);
            break;
          case 'masters':
            setMastersCompleted(value);
            break;
        case 'masters1':
            setMastersCompleted1(value);
            break;
          case 'phd':
            setPhdCompleted(value);
            break;
        case 'phd1':
            setPhdCompleted1(value);
            break;
          default:
            break;
        }
      };
    
    return (
    <>
        <div className="eductionForm">
            <div className="eductionFormBox">
                <div className="eductionFormBoxHead">
                    <h3>Educational Background – Part 1</h3>
                    {/* <IoChevronDownOutline /> */}
                </div>
                <div className="eductionFormBoxBody">
                    <div className="formGroup">
                        <label htmlFor="">I completed 8th standard</label>
                        <div className="formRadioGroup">
                            <div className="formRadioGroupBox">
                                <input type="radio" id='8thStandYes' name="EightthStandYes" value="Yes" checked={education.EightthStandYes === "Yes"} onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                <label htmlFor="8thStandYes">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox">
                                <input type="radio" id='8thStandNo' name="EightthStandYes" value="No" checked={education.EightthStandYes === "No"} onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                <label htmlFor="8thStandNo">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                </label>
                            </div>
                        </div>
                        {educationError.EightthStandYes && <span className="error">{educationError.EightthStandYes}</span>}
                    </div>
                    
                    <div className="col-2 mobDisplayNone"></div>

                    {/* {education.EightthStandYes === 'Yes' && (
                        <>
                        <div className="formGroup col-2">
                            <label htmlFor="">Name of School</label>
                            <input type="text" name="eightthSchool" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}/>
                            {educationError.eightthSchool && <span className="error">{educationError.eightthSchool}</span>}
                        </div>
                        
                        <div className="formGroup col-2">
                            <label htmlFor="">Completion Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={education.EightCompleDate && dayjs(education.EightCompleDate) }
                                        name="EightCompleDate" // Name attribute for identifying field
                                        
                                        onChange={(newValue) => {
                                            if (newValue && newValue.isValid()) {
                                                const formattedValue = newValue.format('DD-MM-YYYY');
                                                handleChangeInput(formattedValue,"EightCompleDate");
                                            }
                                        }}
                                        renderInput={(params) => <input {...params.inputProps} />} // Ensure to use inputProps to spread correctly
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {educationError.EightCompleDate && <span className="error">{educationError.EightCompleDate}</span>}

                        </div>
                        
                        </>
                    )} */}

                    <div className="line"></div>

                    <div className="formGroup">
                        <label htmlFor="">I completed 10th standard</label>
                        <div className="formRadioGroup">
                            <div className="formRadioGroupBox">
                                <input type="radio" id='10thStandYes' name="tenthStandYes" value="Yes" checked={education.tenthStandYes === "Yes"} onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                <label htmlFor="10thStandYes">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox">
                                <input type="radio" id='10thStandNo' name="tenthStandYes" value="No" checked={education.tenthStandYes === "No"}  onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                <label htmlFor="10thStandNo">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                </label>
                            </div>
                        </div>
                        {educationError.tenthStandYes && <span className="error">{educationError.tenthStandYes}</span>}
                    </div>
                    
                    <div className="col-2 mobDisplayNone"></div>

                    {/* {education.tenthStandYes === 'Yes' && (
                        <>
                        <div className="formGroup col-2">
                            <label htmlFor="">Name of School</label>
                            <input type="text" name="tenthSchool" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}/>
                            {educationError.tenthSchool && <span className="error">{educationError.tenthSchool}</span>}
                        </div>
                        
                        <div className="formGroup col-2">
                            <label htmlFor="">Completion Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={education.tenthCompleteDate && dayjs(education.tenthCompleteDate)}
                                        name="tenthCompleteDate"
                                        //onChange={(newValue) => handleChangeInput(newValue,'tenthCompleteDate')}
                                        onChange={(newValue) => {
                                            if (newValue && newValue.isValid()) {
                                                const formattedValue = newValue.format('MM-DD-YYYY');
                                                handleChangeInput(formattedValue,'tenthCompleteDate');
                                            }
                                        }}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {educationError.tenthSchool && <span className="error">{educationError.tenthSchool}</span>}
                        </div>
                        
                        </>
                    )} */}

                    <div className="line"></div>

                    <div className="formGroup">
                        <label htmlFor="">I completed 12th standard</label>
                        <div className="formRadioGroup">
                            <div className="formRadioGroupBox">
                                <input type="radio" id='12thStandYes' name="twelvethStand" value="Yes" checked={education.twelvethStand === "Yes"} onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                <label htmlFor="12thStandYes">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox">
                                <input type="radio" id='12thStandNo' name="twelvethStand" value="No" checked={education.twelvethStand === "No"} onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                <label htmlFor="12thStandNo">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                </label>
                            </div>
                        </div>
                        {educationError.twelvethStand && <span className="error">{educationError.twelvethStand}</span>}
                    </div>
                    
                    <div className="col-2 mobDisplayNone"></div>

                    {/* {education.twelvethStand === 'Yes' && (
                        <>
                        <div className="formGroup col-2">
                            <label htmlFor="">Name of School</label>
                            <input type="text" name="twelveSchool" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}/>
                            {educationError.twelveSchool && <span className="error">{educationError.twelveSchool}</span>}
                        </div>
                        
                        <div className="formGroup col-2">
                            <label htmlFor="">Completion Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                    name="tewlveCompleteDate"
                                    value={education.tewlveCompleteDate && dayjs(education.tewlveCompleteDate)}
                                    onChange={(newValue) => {
                                        if (newValue && newValue.isValid()) {
                                            const formattedValue = newValue.format('DD-MM-YYYY');
                                            handleChangeInput(formattedValue,'tewlveCompleteDate');
                                        }
                                    }}
                                    renderInput={(params) => <input {...params} />}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {educationError.tewlveCompleteDate && <span className="error">{educationError.tewlveCompleteDate}</span>}
                        </div>
                        
                        </>
                    )} */}
                </div>
            </div>
            <div className="eductionFormBox">
                <div className="eductionFormBoxHead">
                    <h3>Educational Background – Part 2</h3>
                    {/* <IoChevronDownOutline /> */}
                </div>
                <div className="eductionFormBoxBody">
                    <div className="formGroup">
                        <div className="formRadioGroup" style={{marginTop: '0px'}}>
                            <div className="formRadioGroupBox">
                                <input type="radio" id='studyCollageNo' name="studyCollage" value="didntAttend" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} checked={education.studyCollage === "didntAttend"}/>
                                <label htmlFor="studyCollageNo">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">I did not attend college/university</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox">
                                <input type="radio" id='studyCollageYesWithout' name="studyCollage"  value="NotComplete" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} checked={education.studyCollage === "NotComplete"}/>
                                <label htmlFor="studyCollageYesWithout">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">I went to college/university, but did not complete it</div>
                                </label>
                            </div>
                            <div className="formRadioGroupBox">
                                <input type="radio" id='studyCollageGraduated' name="studyCollage" value="Complete" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} checked={education.studyCollage === "Complete"} />
                                <label htmlFor="studyCollageGraduated">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">I graduated from college/university</div>
                                </label>
                            </div>
                        </div>
                        {educationError.studyCollage && <span className="error">{educationError.studyCollage}</span>}
                    </div>
                    
                    <div className="col-1 mobDisplayNone"></div>

                    {(education.studyCollage !== 'didntAttend' && education.studyCollage) && (
                        <>
                            <div className="formGroup col-3">
                                <label htmlFor="">Type</label>
                                <input type="text" disabled value="Bachelors Degree" />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Name of College/ University</label>
                                <input type="text" name="NameOfCollege" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} value={education.NameOfCollege} />
                                {educationError.NameOfCollege && <span className="error">{educationError.NameOfCollege}</span>}
                            </div>
                            

                            <div className="formGroup col-3">
                                <label htmlFor="">Completed</label>
                                <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseYesBachelors"
                                    name="completedCourseBachelors"
                                    value="Yes"
                                    checked={education.completedCourseBachelors === "Yes"}
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseYesBachelors">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseNoBachelors"
                                    name="completedCourseBachelors"
                                    value="No"
                                    checked={education.completedCourseBachelors === "No"}
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseNoBachelors">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                    </label>
                                </div>
                                </div>
                                {educationError.completedCourseBachelors && <span className="error">{educationError.completedCourseBachelors}</span>}
                            </div>
                            
                            {education.completedCourseBachelors === 'Yes' && (
                                <div className="formGroup col-3">
                                    <label htmlFor="">Completion Date</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                name="BachelorsCompleteDate"
                                                value={education.BachelorsCompleteDate && dayjs(education.BachelorsCompleteDate)}
                                                onChange={(newValue) => {
                                                    if (newValue && newValue.isValid()) {
                                                        const formattedValue = newValue.format('DD-MM-YYYY');
                                                        handleChangeInput(formattedValue, "BachelorsCompleteDate");
                                                    }
                                                }}
                                                renderInput={(params) => <input {...params} />}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {educationError.BachelorsCompleteDate && (
                                        <span className="error">{educationError.BachelorsCompleteDate}</span>
                                    )}
                                </div>
                            )}

                            

                            {education.completedCourseBachelors === 'No' && (
                                <div className="formGroup col-1">
                                <label htmlFor="">Reason</label>
                                <textarea name="bachelorReason" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} value={education.bachelorReason}/>
                                {educationError.bachelorReason && <span className="error">{educationError.bachelorReason}</span>}
                                </div>
                            )}
                            
                            {/* Divider */}
                            <div className="line"></div>

                            {/* Masters Degree */}
                            <div className="formGroup col-3">
                                <label htmlFor="">Type</label>
                                <input type="text" disabled value="Masters Degree" />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Name of College/ University</label>
                                <input type="text" name="MastersCollege" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} value={education.MastersCollege}/>
                                {educationError.MastersCollege && <span className="error">{educationError.MastersCollege}</span>}
                            </div>
                            
                            <div className="formGroup col-3">
                                <label htmlFor="">Completed</label>
                                <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseYesMasters"
                                    name="completedCourseMasters"
                                    value="Yes"
                                    checked={education.completedCourseMasters === "Yes"}
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseYesMasters">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseNoMasters"
                                    value="No"
                                    name="completedCourseMasters"
                                    checked={education.completedCourseMasters === "No"}
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseNoMasters">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                    </label>
                                </div>
                                </div>
                            </div>

                            {education.completedCourseMasters === 'Yes' && (
                                <div className="formGroup col-3">
                                    <label htmlFor="">Completion Date</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            name="MastersCompleteDate"
                                            value={education.MastersCompleteDate && dayjs(education.MastersCompleteDate)}
                                            onChange={(newValue) => {
                                                if (newValue && newValue.isValid()) {
                                                    const formattedValue = newValue.format('DD-MM-YYYY');
                                                    handleChangeInput(formattedValue,'MastersCompleteDate');
                                                }
                                            }}
                                            renderInput={(params) => <input {...params} />}
                                        />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {educationError.MastersCompleteDate && <span className="error">{educationError.MastersCompleteDate}</span>}
                                </div>
                                
                            )}

                            {education.completedCourseMasters === 'No' && (
                                <div className="formGroup col-1">
                                <label htmlFor="">Reason</label>
                                <textarea name="MastersReason" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} value={education.MastersReason} />
                                {educationError.MastersReason && <span className="error">{educationError.MastersReason}</span>}
                                </div>
                            )}

                            {/* Divider */}
                            <div className="line"></div>

                            {/* PhD Doctorate */}
                            <div className="formGroup col-3">
                                <label htmlFor="">Type</label>
                                <input type="text" disabled value="PhD Doctorate" />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Name of College/ University</label>
                                <input type="text" name="PhdCollege" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} value={education.PhdCollege}/>
                                {educationError.PhdCollege && <span className="error">{educationError.PhdCollege}</span>}
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Completed</label>
                                <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseYesPhd"
                                    name="completedCoursePhd"
                                    value="Yes"
                                    checked={education.completedCoursePhd === "Yes"}
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseYesPhd">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseNoPhd"
                                    name="completedCoursePhd"
                                    value="No"
                                    checked={education.completedCoursePhd === "No"}
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseNoPhd">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                    </label>
                                </div>
                                </div>
                            </div>

                            {education.completedCoursePhd === 'Yes' && (
                                <div className="formGroup col-3">
                                <label htmlFor="">Completion Date</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        name="PhdCompleteDate"
                                        value={education.PhdCompleteDate ? dayjs(education.PhdCompleteDate) : null}
                                        onChange={(newValue) => {
                                            if (newValue && newValue.isValid()) {
                                                const formattedValue = newValue.format('DD-MM-YYYY');
                                                handleChangeInput(formattedValue,'PhdCompleteDate');
                                            }
                                        }}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {educationError.PhdCompleteDate && <span className="error">{educationError.PhdCompleteDate}</span>}
                                </div>
                            )}

                            {education.completedCoursePhd === 'No' && (
                                <div className="formGroup col-1">
                                <label htmlFor="">Reason</label>
                                <textarea name="PhdReason" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} value={education.PhdReason}/>
                                {educationError.PhdReason && <span className="error">{educationError.PhdReason}</span>}
                                </div>
                            )}
                        </>
                    )}

                    {studyCollage === 'studyCollageGraduated' && (
                        <>
                            <div className="formGroup col-3">
                                <label htmlFor="">Type</label>
                                <input type="text" disabled value="Bachelors Degree" />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Name of College/ University</label>
                                <input type="text" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Completed</label>
                                <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseYesBachelors"
                                    name="completedCourseBachelors"
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseYesBachelors">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseNoBachelors"
                                    name="completedCourseBachelors"
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseNoBachelors">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                    </label>
                                </div>
                                </div>
                            </div>

                            {bachelorsCompleted && (
                                <div className="formGroup col-3">
                                <label htmlFor="">Completion Date</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        name="phdCompletedDate"
                                        value={education.phdCompletedDate}
                                        onChange={(newValue) => setCompletionDateBachelors(newValue,'phdCompletedDate')}
                                        
                                        renderInput={(params) => <input {...params} />}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                </div>
                            )}

                            {bachelorsCompleted === false && (
                                <div className="formGroup col-1">
                                <label htmlFor="">Reason</label>
                                <textarea />
                                </div>
                            )}

                            {/* Divider */}
                            <div className="line"></div>

                            {/* Masters Degree */}
                            <div className="formGroup col-3">
                                <label htmlFor="">Type</label>
                                <input type="text" disabled value="Masters Degree" />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Name of College/ University</label>
                                <input type="text" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Completed</label>
                                <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseYesMasters"
                                    name="completedCourseMasters"
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseYesMasters">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseNoMasters"
                                    name="completedCourseMasters"
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseNoMasters">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                    </label>
                                </div>
                                </div>
                            </div>

                            {mastersCompleted && (
                                <div className="formGroup col-3">
                                <label htmlFor="">Completion Date</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={completionDateMasters}
                                        onChange={(newValue) => setCompletionDateMasters(newValue)}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                </div>
                            )}

                            {mastersCompleted === false && (
                                <div className="formGroup col-1">
                                <label htmlFor="">Reason</label>
                                <textarea onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                </div>
                            )}

                            {/* Divider */}
                            <div className="line"></div>

                            {/* PhD Doctorate */}
                            <div className="formGroup col-3">
                                <label htmlFor="">Type</label>
                                <input type="text" disabled value="PhD Doctorate" />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Name of College/ University</label>
                                <input type="text" onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                            </div>
                            <div className="formGroup col-3">
                                <label htmlFor="">Completed</label>
                                <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseYesPhd"
                                    name="completedCoursePhd"
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseYesPhd">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">Yes</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input
                                    type="radio"
                                    id="completedCourseNoPhd"
                                    name="completedCoursePhd"
                                    onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}}
                                    />
                                    <label htmlFor="completedCourseNoPhd">
                                    <div className="radioIcon"></div>
                                    <div className="radioText">No</div>
                                    </label>
                                </div>
                                </div>
                            </div>

                            {phdCompleted && (
                                <div className="formGroup col-3">
                                <label htmlFor="">Completion Date</label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        value={completionDatePhd}
                                        onChange={(newValue) => setCompletionDatePhd(newValue)}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                </div>
                            )}

                            {phdCompleted === false && (
                                <div className="formGroup col-1">
                                <label htmlFor="">Reason</label>
                                <textarea onChange={(e)=>{handleChangeInput(e.target.value,e.target.name)}} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    </>
  );
}

export default EducationForm;
