
import React, { useContext, useState,useEffect } from 'react';
import loginImg from '../../assets/images/login.jpg'
import { ImGoogle } from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import Loader from '../loader/buttonLoader';
import { LoginPopupContext } from '../loginpopup/LoginPopupContext';
const path = "";

const countryCodes = [
    { name: 'Afghanistan', code: '+93', flag: '🇦🇫', phoneLength: 9 },
    { name: 'Albania', code: '+355', flag: '🇦🇱', phoneLength: 9 },
    { name: 'Algeria', code: '+213', flag: '🇩🇿', phoneLength: 9 },
    { name: 'Andorra', code: '+376', flag: '🇦🇩', phoneLength: 6 },
    { name: 'Angola', code: '+244', flag: '🇦🇴', phoneLength: 9 },
    { name: 'Argentina', code: '+54', flag: '🇦🇷', phoneLength: 10 },
    { name: 'Armenia', code: '+374', flag: '🇦🇲', phoneLength: 8 },
    { name: 'Australia', code: '+61', flag: '🇦🇺', phoneLength: 9 },
    { name: 'Austria', code: '+43', flag: '🇦🇹', phoneLength: 10 },
    { name: 'Azerbaijan', code: '+994', flag: '🇦🇿', phoneLength: 9 },
    { name: 'Bahamas', code: '+1-242', flag: '🇧🇸', phoneLength: 7 },
    { name: 'Bahrain', code: '+973', flag: '🇧🇭', phoneLength: 8 },
    { name: 'Bangladesh', code: '+880', flag: '🇧🇩', phoneLength: 10 },
    { name: 'Barbados', code: '+1-246', flag: '🇧🇧', phoneLength: 7 },
    { name: 'Belarus', code: '+375', flag: '🇧🇾', phoneLength: 9 },
    { name: 'Belgium', code: '+32', flag: '🇧🇪', phoneLength: 8 },
    { name: 'Belize', code: '+501', flag: '🇧🇿', phoneLength: 7 },
    { name: 'Benin', code: '+229', flag: '🇧🇯', phoneLength: 8 },
    { name: 'Bhutan', code: '+975', flag: '🇧🇹', phoneLength: 8 },
    { name: 'Bolivia', code: '+591', flag: '🇧🇴', phoneLength: 8 },
    { name: 'Bosnia and Herzegovina', code: '+387', flag: '🇧🇦', phoneLength: 8 },
    { name: 'Botswana', code: '+267', flag: '🇧🇼', phoneLength: 7 },
    { name: 'Brazil', code: '+55', flag: '🇧🇷', phoneLength: 11 },
    { name: 'Brunei', code: '+673', flag: '🇧🇳', phoneLength: 7 },
    { name: 'Bulgaria', code: '+359', flag: '🇧🇬', phoneLength: 7 },
    { name: 'Burkina Faso', code: '+226', flag: '🇧🇫', phoneLength: 8 },
    { name: 'Burundi', code: '+257', flag: '🇧🇮', phoneLength: 8 },
    { name: 'Cabo Verde', code: '+238', flag: '🇨🇻', phoneLength: 7 },
    { name: 'Cambodia', code: '+855', flag: '🇰🇭', phoneLength: 8 },
    { name: 'Cameroon', code: '+237', flag: '🇨🇲', phoneLength: 9 },
    { name: 'Canada', code: '+1', flag: '🇨🇦', phoneLength: 10 },
    { name: 'Central African Republic', code: '+236', flag: '🇨🇫', phoneLength: 7 },
    { name: 'Chad', code: '+235', flag: '🇹🇩', phoneLength: 8 },
    { name: 'Chile', code: '+56', flag: '🇨🇱', phoneLength: 9 },
    { name: 'China', code: '+86', flag: '🇨🇳', phoneLength: 11 },
    { name: 'Colombia', code: '+57', flag: '🇨🇴', phoneLength: 10 },
    { name: 'Comoros', code: '+269', flag: '🇰🇲', phoneLength: 7 },
    { name: 'Congo (Congo-Brazzaville)', code: '+242', flag: '🇨🇬', phoneLength: 8 },
    { name: 'Congo (Democratic Republic of the)', code: '+243', flag: '🇨🇩', phoneLength: 9 },
    { name: 'Costa Rica', code: '+506', flag: '🇨🇷', phoneLength: 8 },
    { name: 'Croatia', code: '+385', flag: '🇭🇷', phoneLength: 9 },
    { name: 'Cuba', code: '+53', flag: '🇨🇺', phoneLength: 8 },
    { name: 'Cyprus', code: '+357', flag: '🇨🇾', phoneLength: 8 },
    { name: 'Czechia', code: '+420', flag: '🇨🇿', phoneLength: 9 },
    { name: 'Denmark', code: '+45', flag: '🇩🇰', phoneLength: 8 },
    { name: 'Djibouti', code: '+253', flag: '🇩🇯', phoneLength: 7 },
    { name: 'Dominica', code: '+1-767', flag: '🇩🇲', phoneLength: 7 },
    { name: 'Dominican Republic', code: '+1-809', flag: '🇩🇴', phoneLength: 10 },
    { name: 'Ecuador', code: '+593', flag: '🇪🇨', phoneLength: 9 },
    { name: 'Egypt', code: '+20', flag: '🇪🇬', phoneLength: 10 },
    { name: 'El Salvador', code: '+503', flag: '🇸🇻', phoneLength: 8 },
    { name: 'Equatorial Guinea', code: '+240', flag: '🇬🇶', phoneLength: 9 },
    { name: 'Eritrea', code: '+291', flag: '🇪🇷', phoneLength: 7 },
    { name: 'Estonia', code: '+372', flag: '🇪🇪', phoneLength: 7 },
    { name: 'Eswatini', code: '+268', flag: '🇸🇿', phoneLength: 8 },
    { name: 'Ethiopia', code: '+251', flag: '🇪🇹', phoneLength: 10 },
    { name: 'Fiji', code: '+679', flag: '🇫🇯', phoneLength: 7 },
    { name: 'Finland', code: '+358', flag: '🇫🇮', phoneLength: 10 },
    { name: 'France', code: '+33', flag: '🇫🇷', phoneLength: 9 },
    { name: 'Gabon', code: '+241', flag: '🇬🇦', phoneLength: 7 },
    { name: 'Gambia', code: '+220', flag: '🇬🇲', phoneLength: 7 },
    { name: 'Georgia', code: '+995', flag: '🇬🇪', phoneLength: 9 },
    { name: 'Germany', code: '+49', flag: '🇩🇪', phoneLength: 11 },
    { name: 'Ghana', code: '+233', flag: '🇬🇭', phoneLength: 9 },
    { name: 'Greece', code: '+30', flag: '🇬🇷', phoneLength: 10 },
    { name: 'Grenada', code: '+1-473', flag: '🇬🇩', phoneLength: 7 },
    { name: 'Guatemala', code: '+502', flag: '🇬🇹', phoneLength: 8 },
    { name: 'Guinea', code: '+224', flag: '🇬🇳', phoneLength: 9 },
    { name: 'Guinea-Bissau', code: '+245', flag: '🇬🇼', phoneLength: 7 },
    { name: 'Guyana', code: '+592', flag: '🇬🇾', phoneLength: 7 },
    { name: 'Haiti', code: '+509', flag: '🇭🇹', phoneLength: 8 },
    { name: 'Honduras', code: '+504', flag: '🇭🇳', phoneLength: 8 },
    { name: 'Hungary', code: '+36', flag: '🇭🇺', phoneLength: 9 },
    { name: 'Iceland', code: '+354', flag: '🇮🇸', phoneLength: 7 },
    { name: 'India', code: '+91', flag: '🇮🇳', phoneLength: 10 },
    { name: 'Indonesia', code: '+62', flag: '🇮🇩', phoneLength: 10 },
    { name: 'Iran', code: '+98', flag: '🇮🇷', phoneLength: 10 },
    { name: 'Iraq', code: '+964', flag: '🇮🇶', phoneLength: 9 },
    { name: 'Ireland', code: '+353', flag: '🇮🇪', phoneLength: 9 },
    { name: 'Israel', code: '+972', flag: '🇮🇱', phoneLength: 9 },
    { name: 'Italy', code: '+39', flag: '🇮🇹', phoneLength: 10 },
    { name: 'Jamaica', code: '+1-876', flag: '🇯🇲', phoneLength: 7 },
    { name: 'Japan', code: '+81', flag: '🇯🇵', phoneLength: 10 },
    { name: 'Jordan', code: '+962', flag: '🇯🇴', phoneLength: 9 },
    { name: 'Kazakhstan', code: '+7', flag: '🇰🇿', phoneLength: 10 },
    { name: 'Kenya', code: '+254', flag: '🇰🇪', phoneLength: 10 },
    { name: 'Kiribati', code: '+686', flag: '🇰🇷', phoneLength: 7 },
    { name: 'Kuwait', code: '+965', flag: '🇰🇼', phoneLength: 8 },
    { name: 'Kyrgyzstan', code: '+996', flag: '🇰🇬', phoneLength: 9 },
    { name: 'Laos', code: '+856', flag: '🇱🇦', phoneLength: 8 },
    { name: 'Latvia', code: '+371', flag: '🇱🇻', phoneLength: 8 },
    { name: 'Lebanon', code: '+961', flag: '🇱🇧', phoneLength: 8 },
    { name: 'Lesotho', code: '+266', flag: '🇱🇸', phoneLength: 8 },
    { name: 'Liberia', code: '+231', flag: '🇱🇷', phoneLength: 7 },
    { name: 'Libya', code: '+218', flag: '🇱🇾', phoneLength: 9 },
    { name: 'Liechtenstein', code: '+423', flag: '🇱🇮', phoneLength: 9 },
    { name: 'Lithuania', code: '+370', flag: '🇱🇹', phoneLength: 8 },
    { name: 'Luxembourg', code: '+352', flag: '🇱🇺', phoneLength: 6 },
    { name: 'Madagascar', code: '+261', flag: '🇲🇬', phoneLength: 9 },
    { name: 'Malawi', code: '+265', flag: '🇲🇼', phoneLength: 9 },
    { name: 'Malaysia', code: '+60', flag: '🇲🇾', phoneLength: 10 },
    { name: 'Maldives', code: '+960', flag: '🇲🇻', phoneLength: 7 },
    { name: 'Mali', code: '+223', flag: '🇲🇱', phoneLength: 8 },
    { name: 'Malta', code: '+356', flag: '🇲🇹', phoneLength: 8 },
    { name: 'Marshall Islands', code: '+692', flag: '🇲🇭', phoneLength: 7 },
    { name: 'Mauritania', code: '+222', flag: '🇲🇦', phoneLength: 8 },
    { name: 'Mauritius', code: '+230', flag: '🇲🇺', phoneLength: 8 },
    { name: 'Mexico', code: '+52', flag: '🇲🇽', phoneLength: 10 },
    { name: 'Micronesia', code: '+691', flag: '🇫🇲', phoneLength: 7 },
    { name: 'Moldova', code: '+373', flag: '🇲🇩', phoneLength: 8 },
    { name: 'Monaco', code: '+377', flag: '🇲🇨', phoneLength: 7 },
    { name: 'Mongolia', code: '+976', flag: '🇲🇳', phoneLength: 8 },
    { name: 'Montenegro', code: '+382', flag: '🇲🇪', phoneLength: 8 },
    { name: 'Morocco', code: '+212', flag: '🇲🇦', phoneLength: 10 },
    { name: 'Mozambique', code: '+258', flag: '🇲🇿', phoneLength: 9 },
    { name: 'Myanmar (formerly Burma)', code: '+95', flag: '🇲🇲', phoneLength: 9 },
    { name: 'Namibia', code: '+264', flag: '🇳🇦', phoneLength: 10 },
    { name: 'Nauru', code: '+674', flag: '🇳🇷', phoneLength: 7 },
    { name: 'Nepal', code: '+977', flag: '🇳🇵', phoneLength: 10 },
    { name: 'Netherlands', code: '+31', flag: '🇳🇱', phoneLength: 9 },
    { name: 'New Zealand', code: '+64', flag: '🇳🇿', phoneLength: 9 },
    { name: 'Nicaragua', code: '+505', flag: '🇳🇮', phoneLength: 8 },
    { name: 'Niger', code: '+227', flag: '🇳🇪', phoneLength: 8 },
    { name: 'Nigeria', code: '+234', flag: '🇳🇬', phoneLength: 10 },
    { name: 'North Korea', code: '+850', flag: '🇰🇵', phoneLength: 9 },
    { name: 'North Macedonia', code: '+389', flag: '🇲🇰', phoneLength: 8 },
    { name: 'Norway', code: '+47', flag: '🇳🇴', phoneLength: 8 },
    { name: 'Oman', code: '+968', flag: '🇴🇲', phoneLength: 8 },
    { name: 'Pakistan', code: '+92', flag: '🇵🇰', phoneLength: 10 },
    { name: 'Palau', code: '+680', flag: '🇵🇼', phoneLength: 7 },
    { name: 'Palestine', code: '+970', flag: '🇵🇸', phoneLength: 9 },
    { name: 'Panama', code: '+507', flag: '🇵🇦', phoneLength: 7 },
    { name: 'Papua New Guinea', code: '+675', flag: '🇵🇬', phoneLength: 7 },
    { name: 'Paraguay', code: '+595', flag: '🇵🇾', phoneLength: 9 },
    { name: 'Peru', code: '+51', flag: '🇵🇪', phoneLength: 9 },
    { name: 'Philippines', code: '+63', flag: '🇵🇭', phoneLength: 10 },
    { name: 'Poland', code: '+48', flag: '🇵🇱', phoneLength: 9 },
    { name: 'Portugal', code: '+351', flag: '🇵🇹', phoneLength: 9 },
    { name: 'Qatar', code: '+974', flag: '🇶🇦', phoneLength: 8 },
    { name: 'Romania', code: '+40', flag: '🇷🇴', phoneLength: 10 },
    { name: 'Russia', code: '+7', flag: '🇷🇺', phoneLength: 10 },
    { name: 'Rwanda', code: '+250', flag: '🇷🇼', phoneLength: 10 },
    { name: 'Saint Kitts and Nevis', code: '+1-869', flag: '🇰🇳', phoneLength: 7 },
    { name: 'Saint Lucia', code: '+1-758', flag: '🇱🇨', phoneLength: 7 },
    { name: 'Saint Vincent and the Grenadines', code: '+1-784', flag: '🇻🇨', phoneLength: 7 },
    { name: 'Samoa', code: '+685', flag: '🇼🇸', phoneLength: 7 },
    { name: 'San Marino', code: '+378', flag: '🇸🇲', phoneLength: 7 },
    { name: 'Sao Tome and Principe', code: '+239', flag: '🇸🇹', phoneLength: 7 },
    { name: 'Saudi Arabia', code: '+966', flag: '🇸🇦', phoneLength: 9 },
    { name: 'Senegal', code: '+221', flag: '🇸🇳', phoneLength: 9 },
    { name: 'Serbia', code: '+381', flag: '🇷🇸', phoneLength: 8 },
    { name: 'Seychelles', code: '+248', flag: '🇸🇨', phoneLength: 7 },
    { name: 'Sierra Leone', code: '+232', flag: '🇸🇱', phoneLength: 8 },
    { name: 'Singapore', code: '+65', flag: '🇸🇬', phoneLength: 8 },
    { name: 'Slovakia', code: '+421', flag: '🇸🇰', phoneLength: 9 },
    { name: 'Slovenia', code: '+386', flag: '🇸🇮', phoneLength: 8 },
    { name: 'Solomon Islands', code: '+677', flag: '🇸🇧', phoneLength: 7 },
    { name: 'Somalia', code: '+252', flag: '🇸🇴', phoneLength: 9 },
    { name: 'South Africa', code: '+27', flag: '🇿🇦', phoneLength: 10 },
    { name: 'South Korea', code: '+82', flag: '🇰🇷', phoneLength: 10 },
    { name: 'South Sudan', code: '+211', flag: '🇸🇸', phoneLength: 9 },
    { name: 'Spain', code: '+34', flag: '🇪🇸', phoneLength: 9 },
    { name: 'Sri Lanka', code: '+94', flag: '🇱🇰', phoneLength: 10 },
    { name: 'Sudan', code: '+249', flag: '🇸🇩', phoneLength: 9 },
    { name: 'Suriname', code: '+597', flag: '🇸🇷', phoneLength: 7 },
    { name: 'Sweden', code: '+46', flag: '🇸🇪', phoneLength: 10 },
    { name: 'Switzerland', code: '+41', flag: '🇨🇭', phoneLength: 9 },
    { name: 'Syria', code: '+963', flag: '🇸🇾', phoneLength: 9 },
    { name: 'Taiwan', code: '+886', flag: '🇹🇼', phoneLength: 10 },
    { name: 'Tajikistan', code: '+992', flag: '🇹🇯', phoneLength: 9 },
    { name: 'Tanzania', code: '+255', flag: '🇹🇿', phoneLength: 10 },
    { name: 'Thailand', code: '+66', flag: '🇹🇭', phoneLength: 9 },
    { name: 'Togo', code: '+228', flag: '🇹🇬', phoneLength: 8 },
    { name: 'Tonga', code: '+676', flag: '🇹🇴', phoneLength: 7 },
    { name: 'Trinidad and Tobago', code: '+1-868', flag: '🇹🇹', phoneLength: 7 },
    { name: 'Tunisia', code: '+216', flag: '🇹🇳', phoneLength: 8 },
    { name: 'Turkey', code: '+90', flag: '🇹🇷', phoneLength: 10 },
    { name: 'Turkmenistan', code: '+993', flag: '🇹🇲', phoneLength: 9 },
    { name: 'Tuvalu', code: '+688', flag: '🇹🇻', phoneLength: 7 },
    { name: 'Uganda', code: '+256', flag: '🇺🇬', phoneLength: 10 },
    { name: 'Ukraine', code: '+380', flag: '🇺🇦', phoneLength: 9 },
    { name: 'United Arab Emirates', code: '+971', flag: '🇦🇪', phoneLength: 9 },
    { name: 'United Kingdom', code: '+44', flag: '🇬🇧', phoneLength: 10 },
    { name: 'United States', code: '+1', flag: '🇺🇸', phoneLength: 10 },
    { name: 'Uruguay', code: '+598', flag: '🇺🇾', phoneLength: 8 },
    { name: 'Uzbekistan', code: '+998', flag: '🇺🇿', phoneLength: 9 },
    { name: 'Vanuatu', code: '+678', flag: '🇻🇺', phoneLength: 7 },
    { name: 'Vatican City', code: '+379', flag: '🇻🇦', phoneLength: 7 },
    { name: 'Venezuela', code: '+58', flag: '🇻🇪', phoneLength: 10 },
    { name: 'Vietnam', code: '+84', flag: '🇻🇳', phoneLength: 9 },
    { name: 'Yemen', code: '+967', flag: '🇾🇪', phoneLength: 9 },
    { name: 'Zambia', code: '+260', flag: '🇿🇲', phoneLength: 10 },
    { name: 'Zimbabwe', code: '+263', flag: '🇿🇼', phoneLength: 9 }
];

  

function Register() {

    const navigate = useNavigate();

    // const handleGetOTP = (e) => {
    //     e.preventDefault();
    //     toast.info('OTP has been sent to your phone');
    //     navigate(`${path}/otp`);
    // };
    
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useContext(LoginPopupContext);
    useEffect(() => {
        const shimmer = document.querySelector('.shimmer');
        shimmer.style.display = 'none'; 
        setIsLoginPopupVisible(false);  
    })
    
    const[first_name,setfirstname]=useState('');
    const[last_name,setlastname]=useState('');
    const[emailval,setemailval]=useState('');
    const[phonenumber,setphonenumber]=useState('');
    const [errors, setErrors] = useState({ first_name: '', emailval: '', phonenumber: '' });
    const [isDisable, setIsDisable] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //const [countryCode, setCountryCode] = useState('+91'); // Default to India

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        console.log("Submitting form with values:", { first_name, emailval, phonenumber });
        // Reset validation errors and alerts
        setErrors({ first_name: '', emailval: '', phonenumber: '' });
        setSuccess(false);
        setError(false);
       let country_code=selectedCountry.code;
        // Validate inputs
        let valid = true;
        if (!first_name.trim()) {
            setErrors(prev => ({ ...prev, first_name: 'First Name is required' }));
            valid = false;
        }
        if (!last_name.trim()) {
            setErrors(prev => ({ ...prev, last_name: 'Last Name is required' }));
            valid = false;
        }
        if (!emailval.trim()|| !/\S+@\S+\.\S+/.test(emailval)) {
            setErrors(prev => ({ ...prev, emailval: 'Valid Email id is required' }));
            valid = false;
        }
        if (phonenumber.length < 6) {
            setErrors(prev => ({ ...prev, phonenumber: 'Valid Phone number is Required' }));  // Fix this line
            valid = false;
        }
        if (valid) {
            setIsLoading(true)
            setIsDisable(true);
            const formData = new FormData();
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('emailval', emailval);
            formData.append('country_code', country_code);
            formData.append('phonenumber', phonenumber);
            
            try {
                const response = await axios.post(`${baseURL}/addregister`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                // Handle success response
                console.log('Success:', response.data);
    
                if (response.data.status === 1) {
                    setSuccess(true);
                    setIsLoading(false)
                    localStorage.setItem('otp_code', response.data.otp_code);
                    localStorage.setItem('emailval', response.data.emailval);
                    localStorage.setItem('login_id', response.data.login_id);
                    localStorage.setItem('candidate_name', response.data.candidate_name);
                    

                    setTimeout(() => {
                        localStorage.removeItem('otp_code');
                    }, 60000);

                    setfirstname('');
                    setlastname('');
                    setemailval('');
                    setphonenumber('');
                    setIsDisable(false); // Enable the button again
                    setTimeout(() => {
                        navigate(`${path}/otp`);
                        //window.location.href = `${path}/`;
                    }, 100);
                } else {
                    setError(true);
                    setIsLoading(false)
                }
    
                // Reset form fields after successful submission
                
            } catch (err) {
                console.log(err)
                setIsLoading(false)
                setError(true);
                setIsDisable(false); // Enable the button again
                if(err.status==422){
                    setErrors(prev => ({ ...prev, emailval: 'Email id already exist' }));
                }
            }
        } else {
            setError(false);
            setIsDisable(false); // Enable the button again
        }
    };
    const [selectedCountry, setSelectedCountry] = useState(countryCodes[74]);
    const setCountryCode = (e) => {
        const country = countryCodes.find(c => c.code === e.target.value);
        setSelectedCountry(country);
    };
    console.log(selectedCountry.code)
  return (
    <>
        <section id="loginSection">
            <div className="container">
                <div className="loginSectionMain">
                    <div className="loginSectionThumbnail">
                        <img src={loginImg} alt="" />
                        <div className="loginSectionThumbnailDetails">
                            <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                        </div>
                    </div>
                    <div className="loginSectionDetails">
                        <h2 className='title'>Create An Account</h2>
                        <p>Enter your details below and take the first step toward new possibilities.</p>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="formGroup">
                                <label htmlFor="">Enter Your First Name</label>
                                <input type="text" 
                                name="first_name"
                                value={first_name}
                                onChange={(e) => setfirstname(e.target.value)}/>
                                {errors.first_name && <p className="error">{errors.first_name}</p>}
                            </div>
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Last Name</label>
                                <input type="text" 
                                name="last_name"
                                value={last_name}
                                onChange={(e) => setlastname(e.target.value)}/>
                                {errors.last_name && <p className="error">{errors.last_name}</p>}
                            </div>
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Email ID</label>
                                <input type="email" 
                                name="emailval"
                                value={emailval}
                                onChange={(e)=>setemailval(e.target.value)}/>
                                {errors.emailval && <p className="error">{errors.emailval}</p>}
                            </div>
                            
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Phone Number</label>
                                {/* <input type="number" 
                                name="phonenumber"
                                value={phonenumber}
                                onChange={(e)=>setphonenumber(e.target.value)}/> */}
                                <div className="phoneInput">
                                    {/* <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)}> */}
                                    <select value={selectedCountry.code} onChange={setCountryCode}> 
                                        {countryCodes.map((country) => (
                                            <option key={country.code} value={country.code}>
                                                {country.flag} {country.code}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                    type="text"
                                    value={phonenumber}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Allow only digits
                                        if (/^\d*$/.test(value)) {
                                        setphonenumber(value);
                                        }
                                    }}
                                    minLength={selectedCountry.phoneLength}
                                    maxLength={selectedCountry.phoneLength}
                                    placeholder="Phone Number"
                                    />
                                    {/* <input type="number" name="phonenumber" value={phonenumber} onChange={(e) => setphonenumber(e.target.value)} 
                                    minLength={country.phoneLength}
                                    maxLength={country.phoneLength}
                                    placeholder={`Enter ${country.phoneLength} digits`}/> */}
                                </div>
                                {/* <div className="error">This phone number not registered</div> */}
                                {errors.phonenumber && <p className="error">{errors.phonenumber}</p>}
                            </div>
                            <div className="formBtnArea">
                                <button type="submit" disabled={isDisable}>{isLoading ? <Loader /> : 'Get OTP'}</button>
                            </div>
                            {/* <div className="orText">Or</div>
                            <Link className="signingGoogle"><ImGoogle /> Sign in with google</Link> */}
                        </form>
                        <div className="logingFooterStrip">
                            <p>I have an account.</p>
                            <Link to={`${path}/login`}>Login Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Register;
