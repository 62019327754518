import React from "react";
import { lazy } from 'react';
import { Link } from "react-router-dom";
import { GoChevronRight } from "react-icons/go";
import AboutImg1 from '../../assets/images/about.jpg'

const path = "";

const HomeAboutThumbnail = lazy(() => import("./homeAboutThumbnail"));

function HomeAbout() {

    return (
        <section id="homeAbout">
            <div className="container">
                <div className="homeAboutMain">
                    <div className="homeAboutThumbnail">
                        <HomeAboutThumbnail homeAboutImg={AboutImg1} />
                    </div>
                    <div className="homeAboutDetails">
                        <h3>About</h3>
                        <h2 className="mainTitle">Daniels Job Finder</h2>
                        <p>Unlock a world of opportunities with Daniel's Recruitment Services! Our expert team specializes in helping individuals achieve their dreams of working, studying, or living abroad. With 20 years of experience in educational services and a team of experts, we guarantee authentic, reliable, and result-driven services.</p>
                        <Link to={`${path}/about`}>Read More <GoChevronRight /></Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeAbout;
