import { createContext, useState, useEffect } from "react";
export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchdata, setsearchDatas] = useState({
    Searchdata: "",
  });

  // useEffect(() => {
  //   // Clear search data whenever location changes
  //   setsearchDatas({ Searchdata: "" });
  // }, [location]);

  return (
    <SearchContext.Provider value={{ searchdata, setsearchDatas }}>
      {children}
    </SearchContext.Provider>
  );
};
