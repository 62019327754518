// components/Loader.js
import React from 'react';
// import { UilCheckCircle } from '@iconscout/react-unicons'

const Loader = () => {
  return (
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Loader;
