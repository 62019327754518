import React, { useState,useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImg from '../../assets/images/login.jpg';
import OTPInput, { ResendOTP } from "otp-input-react";
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import { LoginPopupContext } from '../loginpopup/LoginPopupContext';
const path = "";

function OTP() {
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate();
    
    const isOTPComplete = OTP.length === 4;

   
    const [otpCode, setOtpCode] = useState('');
    const [loginid, setloginid] = useState('');
    const [emailval, setemailval] = useState('');
    const [candidate_name, secandidatename] = useState('');
    
    // useEffect(() => {
    //     const storedOtpCode = localStorage.getItem('otp_code');
    //     const storeloginid = localStorage.getItem('login_id');
    //     const storeemailval = localStorage.getItem('emailval');
    //     const stcandidate_name = localStorage.getItem('candidate_name');

    //     setTimeout(() => {
    //         localStorage.removeItem('otp_code');
    //         storedOtpCode='';
    //         console.log('OTP code removed from localStorage');  // Optional: log to confirm removal
    //     }, 60000);

    //     setOtpCode(storedOtpCode);
    //     setloginid(storeloginid)
    //     setemailval(storeemailval)
    //     secandidatename(stcandidate_name)
    // }, []); 

    useEffect(() => {
        const storedOtpCode = localStorage.getItem('otp_code');
        const storeloginid = localStorage.getItem('login_id');
        const storeemailval = localStorage.getItem('emailval');
        const stcandidate_name = localStorage.getItem('candidate_name');

        setOtpCode(storedOtpCode);
        setloginid(storeloginid);
        setemailval(storeemailval);
        secandidatename(stcandidate_name);
    
        const timer = setTimeout(() => {
            localStorage.removeItem('otp_code');
            setOtpCode(''); 
            console.log('OTP code removed from localStorage');
        }, 60000);
    
        return () => clearTimeout(timer);
    }, []); 

    
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useContext(LoginPopupContext);
    useEffect(() => {
        const shimmer = document.querySelector('.shimmer');
        shimmer.style.display = 'none'; 
        setIsLoginPopupVisible(false);
    }, []);

//console.log(otpCode)
    const handleVerifyClick = async (e) => {
        e.preventDefault();
        if (isOTPComplete) {
            try {
                // Send the OTP to the backend for verification
                const response = await axios.post(`${baseURL}/check_otp_check`, { otp: OTP, otpCode: otpCode, loginid: loginid, emailval: emailval });

                console.log(response.data)
                if (response.data.status == 1) {
                    // Trigger success toast
                    toast.success('OTP Verified Successfully!');
                    localStorage.setItem('jwtToken', response.data.token);
                    localStorage.setItem('roleval', response.data.roleval);
                    // Navigate to the home page
                    setTimeout(() => {
                        //navigate(`${path}/`);
                         window.location.href = `${path}/`;
                    }, 500);
                } else {
                    // Trigger error toast
                    toast.error('Invalid OTP!');
                }
            } catch (error) {
                // Handle error response
                toast.error('Error Verifying OTP. Please Try Again.');
                console.error('Verification error:', error);
            }
        } else {
            toast.error('Please Enter The Complete OTP!');
        }
    };


    //resend

    // const handleResendClick = async () => { 

    //     console.log("Resend clicked");
    //     toast.info('OTP Has Been Resent!');
        
    //     try {
    //         const response = await axios.post(`${baseURL}/resend_otp_register`, { candidate_name: candidate_name,emailval: emailval });
            
    //         console.log(response.data);
    //         if (response.data.status == 1) {
    //             //toast.success('OTP resent successfully!');
    //             localStorage.setItem('otp_code', response.data.otp_code);
    //             setOtpCode(response.data.otp_code);
    //             setTimeout(() => {
    //                 localStorage.removeItem('otp_code');
    //             }, 60000);
    //         } else {
    //             // Trigger error toast
    //             toast.error('Error Resending OTP. Please Try Again.');
    //         }
    //     } catch (error) {
    //         // Handle error response
    //         toast.error('Error Resending OTP. Please Try Again.');
    //         console.error('Verification error:', error);
    //     }
    // };

    const handleResendClick = async () => { 
        console.log("Resend clicked");
        toast.info('OTP Has Been Resent!');
        // Clear OTP input
        setOTP(''); // Clear the OTP input field
    
        try {
            const response = await axios.post(`${baseURL}/resend_otp_register`, { candidate_name: candidate_name, emailval: emailval });
    
            console.log(response.data);
            if (response.data.status == 1) {
    
                localStorage.setItem('otp_code', response.data.otp_code);
                setOtpCode(response.data.otp_code);
    
                setTimeout(() => {
                    localStorage.removeItem('otp_code');
                }, 60000);
            } else {
                toast.error('Error Resending OTP. Please Try Again.');
            }
        } catch (error) {
            toast.error('Error Resending OTP. Please Try Again.');
            console.error('Verification error:', error);
        }
    };
    

    return (
        <>
            <section id="loginSection">
                <div className="container">
                    <div className="loginSectionMain">
                        <div className="loginSectionThumbnail">
                            <img src={loginImg} alt="" />
                            <div className="loginSectionThumbnailDetails">
                                <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                            </div>
                        </div>
                        <div className="loginSectionDetails">
                            <h2 className='title'>Verify Email</h2>
                            <p>Enter the code we sent to your email. Didn’t receive it? Click Resend.</p>
                            <form action="">
                                <div className="formGroup">
                                    <label htmlFor="">Enter OTP</label>
                                    <div className="otpInput">
                                        <OTPInput 
                                            className="otpInputBox" 
                                            value={OTP} 
                                            onChange={setOTP} 
                                            autoFocus 
                                            OTPLength={4} 
                                            otpType="number" 
                                            disabled={false} 
                                        />
                                        <ResendOTP 
                                            className="otpBtnArea" 
                                            onResendClick={handleResendClick} 
                                        />
                                    </div>
                                </div>
                                <div className="formBtnArea">
                                    <button disabled={!isOTPComplete} onClick={handleVerifyClick}>
                                        Verify
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OTP;
