
import React, { useContext, useState } from 'react';
import loginImg from '../../assets/images/login.jpg'
import { ImGoogle } from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import Loader from '../loader/buttonLoader';
const path = "";
function Logintest() {

    const navigate = useNavigate();

    // const handleGetOTP = (e) => {
    //     e.preventDefault();
    //     toast.info('OTP has been sent to your phone');
    //     navigate(`${path}/otp`);
    // };
    
    const[emailval,setemailval]=useState('');
    const[passwordval,setpasswordval]=useState('');
    const [errors, setErrors] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess(false);
       // setError(false);
        setIsLoading(true)
        //setIsDisable(true);
        //const formData = new FormData();
        //formData.append('emailval', emailval);
           
            
            try {
                const response = await axios.post(`${baseURL}/loginaction`, {
                    email: emailval,
                  });
    
                // Handle success response
                console.log('Success:', response.data);
    
                if (response.data.status === 1) {
                    setSuccess(true);
                    setIsLoading(false)
                    localStorage.setItem('jwtToken', response.data.token);
                    localStorage.setItem('login_id', response.data.login_id);
                    localStorage.setItem('roleval', response.data.roleval);
                   
                    setIsDisable(false); // Enable the button again
                    setTimeout(() => {
                        window.location.href = `${path}/`;
                    }, 100);
                } else {
                    setError(true);
                    setIsLoading(false)
                }
    
                // Reset form fields after successful submission
                
            } catch (err) {
                console.log(err)
                setIsLoading(false)
                setError(true);
                setIsDisable(false); // Enable the button again
                if(err.status==422){
                    setErrors(prev => ({ ...prev, emailval: 'Email id already exist' }));
                }

            };
        }
  return (
    <>
        <section id="loginSection">
            <div className="container">
                <div className="loginSectionMain">
                    <div className="loginSectionThumbnail">
                        <img src={loginImg} alt="" />
                        <div className="loginSectionThumbnailDetails">
                            <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                        </div>
                    </div>
                    <div className="loginSectionDetails">
                        <h2 className='title'>Create an account</h2>
                        <p>Enter your details below and take the first step toward new possibilities.</p>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Email ID</label>
                                <input type="email" 
                                name="emailval"
                                value={emailval}
                                onChange={(e)=>setemailval(e.target.value)}/>
                                {errors.emailval && <p className="error">{errors.emailval}</p>}
                            </div>
                            <div className="formGroup">
                                <label htmlFor="">Enter Password</label>
                                <input type="password" 
                                name="passwordval"
                                value={passwordval}
                                onChange={(e)=>setpasswordval(e.target.value)}/>
                                {/* <div className="error">This phone number not registered</div> */}
                                {errors.passwordval && <p className="error">{errors.passwordval}</p>}
                            </div>
                            <div className="formBtnArea">
                                <button type="submit" disabled={isDisable}>{isLoading ? <Loader /> : 'Submit'}</button>
                            </div>
                            {/* <div className="orText">Or</div>
                            <Link className="signingGoogle"><ImGoogle /> Sign in with google</Link> */}
                        </form>
                        <div className="logingFooterStrip">
                            <p>I have an account.</p>
                            <Link to={`${path}/login`}>Login Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Logintest;
