
function JobBanner( {jobBannerTitle}) {
    
  return (
    <>
        <section id="jobBanner">
            <div className="container">
                <div className="jobBannerMain">
                    <h2 className="title">{jobBannerTitle}</h2>
                </div>
            </div>
        </section>
    </>
  );
}

export default JobBanner;
