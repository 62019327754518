import React, { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import JobBanner from "./jobBanner";
import HomeContact from "../home/homeContact";
import { RiSearchLine } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';
import companyLogo7 from '../../assets/images/logo.png';
import emptyImg from '../../assets/images/emptyImg.jpg';
import axios from 'axios';

const path = "";
const JobBox = lazy(() => import("../home/jobBox"));

function SavedJobs() {
  const [jobData, setJobData] = useState([]);
  const [offset, setOffset] = useState(0); // To keep track of fetched jobs
  const limit = 10; // Number of jobs to fetch per request
  const fetchedJobIds = useRef(new Set()); // To track fetched job IDs
  const [allJobsLoaded, setAllJobsLoaded] = useState(false);
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchJobs = async (newOffset) => {
    const login_id = localStorage.getItem('login_id');
    try {
      const response = await axios.get(`${baseURL}/fetch_saved_job_details_all`, {
        params: { offset: newOffset, limit, login_id }
      });
console.log(response.data)
      const newJobs = response.data.jobs.filter(job => !fetchedJobIds.current.has(job.id));
      newJobs.forEach(job => fetchedJobIds.current.add(job.id));

      setJobData(prevData => [...prevData, ...newJobs]);
      setTotalJobs(response.data.total);
      setLoading(false);

      if (jobData.length + newJobs.length >= response.data.total) {
        setAllJobsLoaded(true);
      }
    } catch (error) {
      console.error('Error fetching job data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs(0); // Fetch initial jobs when component mounts

    return () => {
      fetchedJobIds.current.clear(); // Clear job IDs when component unmounts
    };
  }, []);

  const handleShowMore = () => {
    if (!allJobsLoaded) {
      const newOffset = offset + limit;
      setOffset(newOffset); // Update the offset
      fetchJobs(newOffset); // Fetch the next set of jobs
    }
  };

  const companyLogoPath = 'https://danielsjobfinder.com/demo3/public/';

  return (
    <>
      <JobBanner jobBannerTitle="Saved Jobs" />
      <section id="savedJobs">
        <div className="container">
          <div className="savedJobsMain">
            {loading ? (
              <>
                <Suspense>
                  <JobBox
                    jobLink=""
                    jobCompanyImg={`${companyLogoPath}`}
                    jobCompanyName=""
                    jobTitle=""
                    jobLocation=""
                    jobExperiance=""
                    jobSalary=""
                    jobOpening=""
                    jobPostedDate=""
                    applyJobLink=""
                    Shortdescription=""
                  />
                </Suspense>
                <Suspense>
                  <JobBox
                    jobLink=""
                    jobCompanyImg={`${companyLogoPath}`}
                    jobCompanyName=""
                    jobTitle=""
                    jobLocation=""
                    jobExperiance=""
                    jobSalary=""
                    jobOpening=""
                    jobPostedDate=""
                    applyJobLink=""
                    Shortdescription=""
                  />
                </Suspense>
              </>
            ) : jobData.length > 0 ? (
              jobData.map((job, index) => (
                <Suspense key={index}>
                  <JobBox
                    jobLink={`${path}/jobDetails/${job.id}`}
                    jobCompanyImg={companyLogo7}
                    jobCompanyName={job.company_name}
                    jobTitle={job.job_title}
                    jobLocation={job.location_val}
                    jobExperiance={job.experience_range}
                    jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}
                    jobOpening={job.no_openings}
                    jobPostedDate={job.days_ago}
                    applyJobLink={`${path}/applyJobForm/${job.id}`}
                    Shortdescription={job.short_description}
                    jobidval={job.id}
                    savedstatus={job.savedstatus}
                    applystatus={job.apply_status}
                  />
                </Suspense>
              ))
            ) : (
              <div className="noDataFound">
                <div className="noDataFoundIcon">
                  <img src={emptyImg} alt="" />
                </div>
                <div className="noDataFoundDetails">
                  <h3>Opps!</h3>
                  <p>If you don’t find what you are looking for, call/WhatsApp us on 0091 94478 70999 and we will be happy to personally find a good fit for you.</p>
                  <Link to="tel:00919447870999" className="noDataFoundClearFIlterBtn">
                    <IoCallOutline /> Call Now
                  </Link>
                </div>
              </div>
            )}

            {!allJobsLoaded && (
              <div className="jobWraperLoadMore">
                <div className="jobWraperLoadMoreBtn" onClick={handleShowMore}>
                  Show More
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <HomeContact />
    </>
  );
}

export default SavedJobs;
