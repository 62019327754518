import React, { useState, useEffect } from "react";

const PageNotFound = () => {
  const initialTiles = [1, 2, 3, 4, 5, 6, 7, 8, ""];
  const [tiles, setTiles] = useState([...initialTiles]);
  const [message, setMessage] = useState("");
  const [isGameStarted, setIsGameStarted] = useState(false);

  const shuffleTiles = () => {
    const shuffled = [...initialTiles].sort(() => Math.random() - 0.5);
    setTiles(shuffled);
    setMessage(""); // Clear the message when the game is shuffled
  };

  const checkWin = () => {
    const isWin = tiles.slice(0, -1).every((tile, index) => tile === index + 1) && tiles[tiles.length - 1] === "";
    if (isWin) {
      setMessage("Congratulations! You solved the puzzle!");
      setTimeout(() => {
        setMessage(""); // Hide the message after 5 seconds
      }, 5000);
    }
  };
  

  const handleTileClick = (tileIndex) => {
    const emptyIndex = tiles.indexOf("");
    const validMoves = [emptyIndex - 1, emptyIndex + 1, emptyIndex - 3, emptyIndex + 3];

    if (validMoves.includes(tileIndex)) {
      const newTiles = [...tiles];
      newTiles[emptyIndex] = tiles[tileIndex];
      newTiles[tileIndex] = "";
      setTiles(newTiles);
      checkWin(); // Check for win after moving a tile
    }
  };

  useEffect(() => {
    if (!isGameStarted) setTiles([...initialTiles]);
  }, [isGameStarted]);

  return (
    <div className="pageNotFound">
      <div className="eyes">
        <div className="eye">
          <div className="pupil"></div>
        </div>
        <div className="eye">
          <div className="pupil"></div>
        </div>
      </div>
      <h1 className="title">404 - Page Not Found</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
      {!isGameStarted && (
        <button className="button" onClick={() => { shuffleTiles(); setIsGameStarted(true); }}>
          Play a Puzzle Game to Cheer Up!
        </button>
      )}
      {isGameStarted && (
        <>
        <div className="puzzleGrid">
            {tiles.map((tile, index) => (
            <div
                key={index}
                className={`puzzleTile ${tile === "" ? "" : "empty" }`} 
                onClick={() => handleTileClick(index)}
            >
                {tile}
            </div>
            ))}
        </div>
          <button className="button" onClick={shuffleTiles}>
            Reset Puzzle
          </button>
        </>
      )}
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default PageNotFound;
