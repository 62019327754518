import React, { useState, useEffect, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import HomeContact from "../home/homeContact";
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC'; // Axios interceptor for API requests
import emptyImg from '../../assets/images/emptyImg.jpg'
import emptyImg2 from '../../assets/images/emtyImg2.jpg'
import { IoCallOutline } from "react-icons/io5";
const HomeServiceBox = lazy(() => import("../home/homeServiceBox"));

const path = "";

//const website_path="http://127.0.0.1:8000";
const website_path=mediaUrl;

function Service() {
  // State to hold service data
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch services from API
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get(`${baseURL}/services`); // Replace with actual API endpoint
        setServices(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        setError("Failed to fetch services data");
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) {
    return <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Experience Our World-Class Services | Daniel's Job Finder</title>
                    <meta name="description" content="Discover exceptional services at Daniel’s Job Finder. We offer special advice to job seekers and students interested in Seeking higher studies in foreign countries" />
                    <link rel="canonical" href="https://danielsjobfinder.com/service" />
                    <meta name="keywords" content="overseas job consultants in kerala, overseas education consultants kerala" />
                    <meta property="og:title" content="Experience Our World-Class Services | Daniel's Job Finder" />
                    <meta property="og:description" content="Discover exceptional services at Daniel’s Job Finder. We offer special advice to job seekers and students interested in Seeking higher studies in foreign countries" />
                </Helmet>
                <section id="serviceSection">
                    <div className="container">
                        <h2 className="title">What we do</h2>
                        <div className="serviceSectionHead">
                        <div className="serviceSectionHeadCol1">
                            <h3 className="mainTitle">Seamless Job Search and Career Advancement</h3>
                        </div>
                        <div className="serviceSectionHeadCol2">
                            <p>Discover your next career move with our comprehensive Job Search feature. Seamlessly search, apply, and track opportunities, all within one platform. Empower your professional journey with tailored job recommendations, real-time alerts, and advanced search tools designed to match you with the role of your dreams.</p>
                        </div>
                        </div>
                        <div className="serviceSectionList">
                        
                            <Suspense key={1}>
                                <HomeServiceBox 
                                    homeServiceBoxLink={`${path}/innerService/${''}`} 
                                    homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                    homeServiceBoxTitle={ "No title available"} 
                                    homeServiceBoxDesc={ "No description available"} 
                                />
                            </Suspense>
                            <Suspense key={2}>
                                <HomeServiceBox 
                                    homeServiceBoxLink={`${path}/innerService/${''}`} 
                                    homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                    homeServiceBoxTitle={ "No title available"} 
                                    homeServiceBoxDesc={ "No description available"} 
                                />
                            </Suspense>
                            <Suspense key={3}>
                                <HomeServiceBox 
                                    homeServiceBoxLink={`${path}/innerService/${''}`} 
                                    homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                    homeServiceBoxTitle={ "No title available"} 
                                    homeServiceBoxDesc={ "No description available"} 
                                />
                            </Suspense>
                            <Suspense key={4}>
                                <HomeServiceBox 
                                    homeServiceBoxLink={`${path}/innerService/${''}`} 
                                    homeServiceBoxThumbnail={`https://danielsjobfinder.com/demo3/public/hi`|| ''} // Replace with dynamic image URL if available
                                    homeServiceBoxTitle={ "No title available"} 
                                    homeServiceBoxDesc={ "No description available"} 
                                />
                            </Suspense>
                        
                        </div>
                    </div>
                </section>

                <HomeContact />
            </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="serviceSection">
        <div className="container">
          <h2 className="title">What we do</h2>
          <div className="serviceSectionHead">
            <div className="serviceSectionHeadCol1">
              <h3 className="mainTitle">Seamless Job Search and Career Advancement</h3>
            </div>
            <div className="serviceSectionHeadCol2">
              <p>Discover your next career move with our comprehensive Job Search feature. Seamlessly search, apply, and track opportunities, all within one platform. Empower your professional journey with tailored job recommendations, real-time alerts, and advanced search tools designed to match you with the role of your dreams.</p>
            </div>
          </div>
          <div className="serviceSectionList">
            {services.length > 0 ? (
            services.map((service) => (
              <Suspense key={service.id}>
                <HomeServiceBox 
                  homeServiceBoxLink={`${path}/service/${service.service_name_format}/${service.id}`} 
                  homeServiceBoxThumbnail={website_path+service.service_image|| ''} // Replace with dynamic image URL if available
                  homeServiceBoxTitle={service.service_name || "No title available"} 
                  homeServiceBoxDesc={service.service_description || "No description available"} 
                />
              </Suspense>
            ))
          ):(
              <div className="noDataFound">
                <div className="noDataFoundIcon">
                <img src={emptyImg2} alt="" />
                </div>
                <div className="noDataFoundDetails">
                <h3>Opps!</h3>
                <p>Service Not Available</p>
                </div>
            </div>
          )}
          </div>
        </div>
      </section>

      <HomeContact />
    </>
  );
}

export default Service;
