import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from "react-icons/md";


function BackBtn() {

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };
    
    return (
        <>
            <div className="backBtnStrip">
                <div className="backBtnStripCol1">
                    <div className="backBtn" onClick={handleBackClick}><MdArrowBack /> Back</div>
                </div>
            </div>
        </>
    );
}

export default BackBtn;
