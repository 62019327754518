import React, { createContext, useState } from 'react';

// Create a context for the login popup state with a default value
export const LoginPopupContext = createContext({
    isLoginPopupVisible: false,
    setIsLoginPopupVisible: () => {}
});

export const LoginPopupProvider = ({ children }) => {
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);

    return (
        <LoginPopupContext.Provider value={{ isLoginPopupVisible, setIsLoginPopupVisible }}>
            {children}
        </LoginPopupContext.Provider>
    );
};
