import React, { useState, useEffect, lazy } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { GoChevronRight, GoArrowRight, GoArrowLeft } from "react-icons/go";
import axios from "axios"; // Assuming you are using Axios for API calls
import api, { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import emptyImg from '../../assets/images/emptyImg.jpg'
import { IoCallOutline } from "react-icons/io5";

// Lazy load blog box component
const HomeBlogBox = lazy(() => import("./homeBlogBox"));

const path = ""; // Update with your actual base path

function HomeBlog() {
    const [blogs, setBlogs] = useState([]); // State to hold blogs
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
   
    const page = 1; // Default page
    const limit = 5; // Limit blogs to 5
    const searchTerm = ''; // Search term, empty by default

    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    // Fetch blogs from the API
    useEffect(() => {
        const fetchBlogs = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseURL}/blog_list_front`, {
                    params: {
                        page,
                        limit,
                        search: searchTerm,
                    },
                });
                if (response.data && response.data.data) {
                    setBlogs(response.data.data);
                    console.log(response.data.data) // Set the blogs
                } else {
                    setBlogs([]);
                }
            } catch (error) {
                console.error("Error fetching blogs:", error);
                setError("Failed to fetch blogs");
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []); // Fetch only once on component mount

    if (loading) return <section id="homeBlog">
                            <div className="container">
                                <div className="homeBlogTitle">
                                    <h2 className="title">Latest Blogs</h2>
                                    <h3 className="mainTitle">Unlocking Success, Simple Habits, Big Results</h3>
                                </div>
                                <div className="homeBlogMain">
                                    <Slider {...settings}>
                                        
                                            
                                        <div key={1} className="item">
                                            <HomeBlogBox
                                                homeBlogLink={``}
                                                homeBlogImg={`https://danielsjobfinder.com/demo3/public/hi`}
                                                homeBlogTime={''}
                                                homeBlogTitle={''}
                                                homeBlogDesc={''}
                                            />
                                        </div>
                                        <div key={2} className="item">
                                            <HomeBlogBox
                                                homeBlogLink={``}
                                                homeBlogImg={`https://danielsjobfinder.com/demo3/public/hi`}
                                                homeBlogTime={''}
                                                homeBlogTitle={''}
                                                homeBlogDesc={''}
                                            />
                                        </div>
                                        <div key={3} className="item">
                                            <HomeBlogBox
                                                homeBlogLink={``}
                                                homeBlogImg={`https://danielsjobfinder.com/demo3/public/hi`}
                                                homeBlogTime={''}
                                                homeBlogTitle={''}
                                                homeBlogDesc={''}
                                            />
                                        </div>
                                        <div key={4} className="item">
                                            <HomeBlogBox
                                                homeBlogLink={``}
                                                homeBlogImg={`https://danielsjobfinder.com/demo3/public/hi`}
                                                homeBlogTime={''}
                                                homeBlogTitle={''}
                                                homeBlogDesc={''}
                                            />
                                        </div>
                                        
                                    </Slider>
                                </div>
                                <div className="homeBlogFooter">
                                    <Link to={`${path}/blog`}>
                                        View More <GoChevronRight />
                                    </Link>
                                </div>
                            </div>
                        </section>;
    if (error) return <div>{error}</div>;

    return (
        <>
        {blogs.length > 0 && (
        <section id="homeBlog">
            <div className="container">
                <div className="homeBlogTitle">
                    <h2 className="title">Latest Blogs</h2>
                    <h3 className="mainTitle">Unlocking Success, Simple Habits, Big Results</h3>
                </div>
                <div className="homeBlogMain">
                    
                        {blogs.length > 0 ? (
                            <Slider {...settings}>
                            {blogs.map((blog, index) => (
                                <div key={index} className="item">
                                    <HomeBlogBox
                                        homeBlogLink={`${path}/${blog.blog_title_format}/${blog.id}`}
                                        homeBlogImg={mediaUrl + blog.blog_image}
                                        homeBlogTime={blog.added_date}
                                        homeBlogTitle={blog.blog_title}
                                        homeBlogDesc={blog.blog_description}
                                    />
                                </div>
                            ))}
                            </Slider>
                        ) : (
                            <div className="noDataFound">
                                <div className="noDataFoundIcon">
                                <img src={emptyImg} alt="" />
                                </div>
                                <div className="noDataFoundDetails">
                                <h3>Opps!</h3>
                                <p>If you don’t find what you are looking for, call/WhatsApp us on 0091 94478 70999 and we will be happy to personally find a good fit for you.</p>
                                <Link to="tel:00919447870999" className="noDataFoundClearFIlterBtn">
                                    <IoCallOutline /> Call Now
                                </Link>
                                </div>
                            </div>
                        )}
                </div>
                {blogs.length > 0 && (
                    <div className="homeBlogFooter">
                        <Link to={`${path}/blog`}>
                            View More <GoChevronRight />
                        </Link>
                    </div>
                )}
            </div>
        </section>
    )}
    </>
    );
}

// Custom Next and Prev Arrow components for the Slider
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowRight />
        </button>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowLeft />
        </button>
    );
}

export default HomeBlog;
