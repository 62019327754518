import React from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { RiDeleteBinLine, RiDownloadLine } from "react-icons/ri";
import axios from 'axios';
import api, { baseURL ,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';
function UploadResume({ onHandleImageUpload, existingResume ,reumenameval}) {

    const [resume, setResume] = React.useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [isPopupVisible, setIsPopupVisible] = React.useState(false);

    // Fetch shimmer, body, and form list DOM elements (useRef is recommended over querySelector for React apps)
    const shimmer = React.useRef(document.querySelector('.shimmer'));
    const body = React.useRef(document.querySelector('body'));
    const applyJobFormList = React.useRef(document.querySelector('.applyJobFormList'));

    // Load existing resume if passed as a prop
    React.useEffect(() => {
        if (existingResume) {
            setResume(existingResume);
        }
    }, [existingResume]);

    // File drop handler
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const isValidFileType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type);
        const isValidFileSize = file.size <= 2 * 1024 * 1024; // 2MB
    
        if (isValidFileType && isValidFileSize) {
            setResume(file);
            onHandleImageUpload(file); // Call the function passed as a prop
            setAlertMessage(''); // Clear alert message on valid upload
        } else {
            setAlertMessage('Please upload a valid file type (doc, docx, pdf) with size less than 2MB.');
        }
    };

    const handleRemoveResume = () => {
        setResume(null);
        setAlertMessage(''); // Clear alert message when resume is removed
    };

    const handleDeleteResumeClick = () => {
        setIsPopupVisible(true);
        shimmer.current.style.display = 'block';
        body.current.style.overflow = 'hidden';
        applyJobFormList.current.style.position = 'inherit';
    };

    const handleCancelClick = () => {
        setIsPopupVisible(false);
        shimmer.current.style.display = 'none';
        body.current.style.overflow = 'inherit';
        applyJobFormList.current.style.position = 'sticky';
    };

    const handleConfirmDeleteClick = () => {
        handleRemoveResume();
        onHandleImageUpload('')
        setIsPopupVisible(false);
        shimmer.current.style.display = 'none';
        body.current.style.overflow = 'inherit';
        applyJobFormList.current.style.position = 'sticky';
        toast.success('Resume Deleted Successfully!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.doc,.docx,.pdf',
        maxSize: 2 * 1024 * 1024,
    });

    const handleDownload = (resume1) => {
        // Assuming resume is the filename, not the full URL
        const resume = resume1.substring(resume1.lastIndexOf('/') + 1);
        const fileUrl = `${baseURL}/downloadresume`;
    
        axios.post(fileUrl, { resume: resume }, { responseType: 'blob' })  // Send just the filename
            .then(response => {
                //console.log(response.data)
                // Create a URL for the file blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', resume);  // Specify a name for the downloaded file
                document.body.appendChild(link);
                link.click();
                link.remove();  // Clean up
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };

    return (
        <>
            {isPopupVisible && (
                <div className="deleteConfirmPopup">
                    <div className="deleteConfirmPopupMain">
                        <h2>Are you sure you want to delete the resume?</h2>
                        <div className="deleteConfirmPopupFooter">
                            <div className="closeDeleteConfirmPopup" onClick={handleCancelClick}>Cancel</div>
                            <div className="confirmDeleteConfirmPopup" onClick={handleConfirmDeleteClick}>Delete</div>
                        </div>
                    </div>
                </div>
            )}

            <div {...getRootProps({ className: 'uploadResumeBox' })}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag & drop a resume here, or click to select one</p>
                }
            </div>
            {alertMessage && (
                <div className="uploadResumeAlert">{alertMessage}</div>
            )}
            {resume && (
                <div className="resumeList">
                    <div className="resumeListBox">
                        <div className="resumeListBoxCol1">
                            {typeof resume === 'string' ? (
                                // If resume is a URL (existing resume), display a clickable link
                                <p>
                                    {resume && resume.split('/').pop()}
                                </p>
                            ) : (
                                // If resume is a file object (uploaded), display file name
                                <p>{resume.name}</p>
                            )}
                        </div>
                        <div className="resumeListBoxCol2">
                            {console.log(resume)}
                            <a 
                                href={typeof resume === 'string' || URL.createObjectURL(resume)} 
                                onClick={typeof resume === 'string' ? () => handleDownload(resume) : null}
                                download 
                                className="resumeListbtn downloadResume"
                            >
                                <RiDownloadLine />
                            </a>

                            {/* <button type="button" className='resumeListbtn deleteResume' onClick={handleDeleteResumeClick}>
                                <RiDeleteBinLine />
                            </button> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default UploadResume;
