import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import HomeContact from "../home/homeContact";
import AvatarImg from '../../assets/images/avatar.png'; // Fallback avatar
import axios from 'axios';
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC'; // Axios interceptor
import emptyImg from '../../assets/images/emptyImg.jpg'
import emptyImg2 from '../../assets/images/emtyImg2.jpg'
import { IoCallOutline } from "react-icons/io5";
const HomeTestimonialsBox = React.lazy(() => import("../home/homeTestimonialsBox"));
const mediaPath = mediaUrl;
function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch testimonials from API
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await api.get(`${baseURL}/testimonials`); // Replace with actual API endpoint
        setTestimonials(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        setError('Failed to fetch testimonials');
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (loading) {
    return <>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Candidate Testimonials and Reviews – Daniel's Job Finder</title>
              <meta name="description" content="Search for candidate’s testimonials and reviews on Daniel’s Job Finder. Find out how we assist students and other people in search of their dream jobs. Visit us" />
              <link rel="canonical" href="https://danielsjobfinder.com/testimonials" />
              <meta name="keywords" content="overseas education consultants Kerala, study abroad consultants in Kochi" />
              <meta property="og:title" content="Candidate Testimonials and Reviews – Daniel's Job Finder" />
              <meta property="og:description" content="Search for candidate’s testimonials and reviews on Daniel’s Job Finder. Find out how we assist students and other people in search of their dream jobs. Visit us" />
          </Helmet>
            <section id="testimonialsSection">
              <div className="container">
                <div className="testimonialsSectionTitle">
                  {/* <h2 className="title">Testimonials</h2> */}
                  <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
                </div>
                <div className="testimonialsSectionList">
                  
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={''}
                        homeTestimonialsIframe={''}
                        homeTestimonialsImg={mediaUrl+'hi'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={''}
                        homeTestimonialsDesignation={''}
                        testimonialtype={''}
                        testimonialImagewithurl={mediaUrl+'hi'}
                        testimonialImage={mediaUrl+'hi'}
                        testimonialcheck={''}
                      />
                    </React.Suspense>
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={''}
                        homeTestimonialsIframe={''}
                        homeTestimonialsImg={mediaUrl+'hi'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={''}
                        homeTestimonialsDesignation={''}
                        testimonialtype={''}
                        testimonialImagewithurl={mediaUrl+'hi'}
                        testimonialImage={mediaUrl+'hi'}
                        testimonialcheck={''}
                      />
                    </React.Suspense>
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={''}
                        homeTestimonialsIframe={''}
                        homeTestimonialsImg={mediaUrl+'hi'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={''}
                        homeTestimonialsDesignation={''}
                        testimonialtype={''}
                        testimonialImagewithurl={mediaUrl+'hi'}
                        testimonialImage={mediaUrl+'hi'}
                        testimonialcheck={''}
                      />
                    </React.Suspense>
                    <React.Suspense >
                      <HomeTestimonialsBox
                        homeTestimonialsContent={''}
                        homeTestimonialsIframe={''}
                        homeTestimonialsImg={mediaUrl+'hi'} // Use fallback avatar if imgUrl is unavailable
                        homeTestimonialsName={''}
                        homeTestimonialsDesignation={''}
                        testimonialtype={''}
                        testimonialImagewithurl={mediaUrl+'hi'}
                        testimonialImage={mediaUrl+'hi'}
                        testimonialcheck={''}
                      />
                    </React.Suspense>
                  
                </div>
              </div>
            </section>

            <HomeContact />
          </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="testimonialsSection">
        <div className="container">
          <div className="testimonialsSectionTitle">
            {/* <h2 className="title">Testimonials</h2> */}
            <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
          </div>
          <div className="testimonialsSectionList">
          {testimonials.length > 0 ? (
              testimonials.map((testimonial, index) => (
                <React.Suspense key={index}>
                  <HomeTestimonialsBox
                    homeTestimonialsContent={testimonial.testiomnial_desc}
                    homeTestimonialsIframe={testimonial.iframe_val}
                    homeTestimonialsImg={testimonial.imgUrl || AvatarImg} // Use fallback avatar if imgUrl is unavailable
                    homeTestimonialsName={testimonial.person_name}
                    homeTestimonialsDesignation={testimonial.designation}
                    testimonialtype={testimonial.testimonialtype}
                    testimonialImagewithurl={mediaUrl+testimonial.testimonialImage}
                    testimonialImage={testimonial.testimonialImage}
                    testimonialcheck={2}
                  />
                </React.Suspense>
              ))
          ):(
                <div className="noDataFound">
                    <div className="noDataFoundIcon">
                    <img src={emptyImg2} alt="" />
                    </div>
                    <div className="noDataFoundDetails">
                    <h3>Opps!</h3>
                    <p>Testimonials Not Available</p>
                    </div>
                </div>
          )}
          </div>
        </div>
      </section>

      <HomeContact />
    </>
  );
}

export default Testimonials;
