import React, { useState,useEffect } from 'react';
import { MdOutlineModeEdit, MdOutlineCheckCircle } from "react-icons/md";
import { RiDeleteBinLine, RiDownloadLine } from "react-icons/ri";
import { BiCloudUpload } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';
import api, { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC';
function ReviewForm({ setValue,basicDatails,resumeFile,experience,education }) {
    const mediaPath  = mediaUrl
    console.log(experience)
    const [resumeList, setResumeList] = useState([
        resumeFile, 
    ]);
    const [loginId,setLoginId] = useState(1);
    const [personalDetails,setFormDataperson]=useState([]);
    const [language,setLanguage] = useState([]);
    console.log(resumeFile)
    //setResumeList([resumeFile])
    const [educationList, setEducationList] = useState([
        {
            id: 1,
            degree: 'Class 10th',
            board: 'CBSE',
            passingYear: '2024'
        },
        {
            id: 2,
            degree: 'Class 12th',
            board: 'CBSE',
            passingYear: '2024'
        },
        {
            id: 3,
            degree: 'Masters / Post-Graduation',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
        {
            id: 4,
            degree: 'Doctorate / PhD',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
    ]);
    useEffect(() => {
        const storedOtpCode = localStorage.getItem('otp_code');
        const storeloginid = localStorage.getItem('login_id');
        const storeemailval = localStorage.getItem('emailval');
        const stcandidate_name = localStorage.getItem('candidate_name');

        
        setLoginId(storeloginid)
       
    }, []);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemType, setItemType] = useState('');
    // const [selectedResume, setSelectedResume] = React.useState(null);
    const [isPopupVisible, setIsPopupVisible] = React.useState(false);
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const applyJobFormList = document.querySelector('.applyJobFormList');

    const handleDeleteClick = (item, type) => {
        setSelectedItem(item);
        setItemType(type);
        setIsPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('.applyJobFormList').style.position = 'inherit';
    };

    // const handleDeleteResumeClick = (resume) => {
    //     setSelectedResume(resume);
    //     setIsPopupVisible(true);
    //     shimmer.style.display = 'block';
    //     body.style.overflow = 'hidden';
    //     applyJobFormList.style.position = 'inherit';
    // };

    const handleCancelClick = () => {
        setIsPopupVisible(false);
        shimmer.style.display = 'none';
        body.style.overflow = 'inherit';
        applyJobFormList.style.position = 'sticky';
    };

    const handleConfirmDeleteClick = () => {
        if (itemType === 'resume') {
            setResumeList(prevList => prevList.filter(resume => resume !== selectedItem));
            toast.success('Resume Deleted Successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        } else if (itemType === 'education') {
            setEducationList(prevList => prevList.filter(education => education.id !== selectedItem.id));
            toast.success('Education Detail Deleted Successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        }
        setSelectedItem(null);
        setIsPopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        document.querySelector('body').style.overflow = 'inherit';
        document.querySelector('.applyJobFormList').style.position = 'sticky';
    };

    const handleEditClick = (tabIndex) => {
        setValue(tabIndex);
    };

    useEffect(() => {
        const fetchPersonalDetailsReview = async () => {
            try {
                const storeloginid = await localStorage.getItem('login_id');
                const response = await axios.get(`${baseURL}/getPersonalDetails/${storeloginid}`);
                //console.log(response);
    
                if (response.data.status === 'success') {
                    const data = response.data.data;
                    setFormDataperson({
                        gender_val: data.gender_val || '',
                        marital_status: data.marital_status || '',
                        day_val: data.day_val || '',
                        month_val: data.month_val || '',
                        year_val: data.year_val || '',
                        permenent_adress: data.permenent_adress || '',
                        home_town: data.home_town || '',
                        pincode_val: data.pincode_val || '',
                        language: data.language || [],
                    });
                    setLanguage(data.language)
                } else {
                    console.error('Fetch failed: ', response.data.message);
                }
            } catch (err) {
                console.error('Error fetching personal details:', err);
            }
        };
    
        fetchPersonalDetailsReview();
    }, [loginId]);
    
   //console.log(personalDetails)

   const handleDownload = (resume1) => {
    // Assuming resume is the filename, not the full URL
        const resume = resume1.substring(resume1.lastIndexOf('/') + 1);
        const fileUrl = `${baseURL}/downloadresume`;

        axios.post(fileUrl, { resume: resume }, { responseType: 'blob' })  // Send just the filename
            .then(response => {
                //console.log(response.data)
                // Create a URL for the file blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', resume);  // Specify a name for the downloaded file
                document.body.appendChild(link);
                link.click();
                link.remove();  // Clean up
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };
  return (
    <>
    {isPopupVisible && (
        <div className="deleteConfirmPopup">
            <div className="deleteConfirmPopupMain">
                <h2>
                    {itemType === 'resume' 
                        ? 'Are you sure you want to delete this resume?' 
                        : 'Are you sure you want to delete this education detail?'}
                </h2>
                <div className="deleteConfirmPopupFooter">
                    <div className="closeDeleteConfirmPopup" onClick={handleCancelClick}>Cancel</div>
                    <div className="confirmDeleteConfirmPopup" onClick={handleConfirmDeleteClick}>Delete</div>
                </div>
            </div>
        </div>
    )}
        <div className="reviewBoxList">
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Profile</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <MdOutlineModeEdit />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(0)}>Edit</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="formGroup">
                        <label htmlFor="">Name</label>
                        <h4>{(basicDatails.firstname || basicDatails.lastname)? basicDatails.firstname +' '+basicDatails.lastname :'Your Name'}</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Phone Number</label>
                        <h4>{basicDatails.phonenumber ? (basicDatails.country_code + ' '+ basicDatails.phonenumber) :'Your Phone Number'}</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Email</label>
                        <h4>{basicDatails.emailid ? basicDatails.emailid :'Your Email Id'}</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Work Status</label>
                        
                        <h4>{(basicDatails.work_status ) ? (basicDatails.work_status === 'fresher' ? 'Fresher':'Experienced' ) :'Your Work Status'}</h4>
                    </div>
                {/* {console.log(basicDatails)} */}
                {console.log(basicDatails.work_status)}
                {basicDatails.work_status === "experience" &&(
                    <div className="formGroup col-1">
                        <div className="workHistory">
                            <label htmlFor="">Work Status</label>
                            <div className="workHistoryBody">
                            {console.log(experience)}
                            {basicDatails.work_status === 'experience' && experience.map((row, index) => (
                                <div key={index} className="workHistoryBodyRow">
                                    <div className="workHistoryBodyBox">
                                        <label htmlFor="" className="workHistoryLabel">Start Date</label>
                                        <h4>{row.startDate}</h4>
                                    </div>
                                    <div className="workHistoryBodyBox">
                                        <label htmlFor="" className="workHistoryLabel">End Date</label>
                                        <h4>{row.endDate || 'Present'}</h4>
                                    </div>
                                    <div className="workHistoryBodyBox">
                                        <label htmlFor="" className="workHistoryLabel">Name of Organization / Company</label>
                                        <h4>{row.companyName}</h4>
                                    </div>
                                    <div className="workHistoryBodyBox">
                                        <label htmlFor="" className="workHistoryLabel">Position</label>
                                        <h4>{row.position}</h4>
                                    </div>
                                </div>
                            )) }

                                
                            </div>
                        </div>
                    </div>
                        )
                    }
                    <div className="formGroup">
                        <label htmlFor="">Current location</label>
                        <h4>{(basicDatails.counrtryselect || basicDatails.placeselect)  ? basicDatails.placeselect+','+basicDatails.counrtryselect :'Your Location'}</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Profile Summary</label>
                        <h4>{basicDatails.profilesummary ? basicDatails.profilesummary :'Your Profile Summary'}</h4>
                    </div>
                </div>
            </div>
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Resume</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <BiCloudUpload />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(1)}>Upload Resume</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="resumeList">
                        {resumeList.map((resume, index) => (
                            <div className="resumeListBox" key={index}>
                                <div className="resumeListBoxCol1">
                                    <p>{resume && resume.split('/').pop()}</p>
                                </div>
                                <div className="resumeListBoxCol2">
                                    <Link  className="resumeListbtn downloadResume" onClick={typeof resume === 'string' ? () => handleDownload(resume) : null}>
                                        <RiDownloadLine />
                                    </Link>
                                    {/* <button 
                                        type="button" 
                                        className='resumeListbtn deleteResume' 
                                        onClick={() => handleDeleteClick(resume, 'resume')}>
                                        <RiDeleteBinLine />
                                    </button> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Education Details</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <MdOutlineModeEdit />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(2)}>Edit</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="educationList">
                        
                            <div className="educationListBox" >
                                <div className="educationListBoxHead">
                                    <div className="educationListBoxHeadCol1">
                                        <div className="educationTitle">8th Standard</div>
                                    </div>
                                    
                                </div>
                                <div className="educationListBoxBody">
                                    <div className="formGroup">
                                        <label htmlFor="">Completed?</label>
                                        <h4>{education.EightthStandYes || 'No Data'}</h4>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="educationListBox" >
                                <div className="educationListBoxHead">
                                    <div className="educationListBoxHeadCol1">
                                        <div className="educationTitle">10th Standard</div>
                                    </div>
                                    
                                </div>
                                <div className="educationListBoxBody">
                                    <div className="formGroup">
                                        <label htmlFor="">Completed?</label>
                                        <h4>{education.tenthStandYes || 'No Data'}</h4>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="educationListBox" >
                                <div className="educationListBoxHead">
                                    <div className="educationListBoxHeadCol1">
                                        <div className="educationTitle">12th Standard</div>
                                    </div>
                                    
                                </div>
                                <div className="educationListBoxBody">
                                    <div className="formGroup">
                                        <label htmlFor="">Completed?</label>
                                        <h4>{education.twelvethStand || 'No Data'}</h4>
                                    </div>
                                    
                                </div>
                            </div>
                        {education.studyCollage !== 'didntAttend' && (
                            <>
                                {education.completedCourseBachelors  && (
                                    <div className="educationListBox" >
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">Bachelors Degree</div>
                                            </div>
                                        </div>
                                        <div className="educationListBoxBody">
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Name of College/ University</label>
                                                <h4>{education.NameOfCollege || 'No Data'}</h4>
                                            </div>
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Completed?</label>
                                                <h4>{education.completedCourseBachelors || 'No Data'}</h4>
                                            </div>
                                            {education.completedCourseBachelors === 'Yes' &&
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completion Date</label>
                                                    <h4>{education.BachelorsCompleteDate}</h4>
                                                </div>
                                            }
                                            {education.completedCourseBachelors === 'No' &&
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Reason</label>
                                                    <h4>{education.bachelorReason}</h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                                {education.completedCourseMasters  && (
                                    <div className="educationListBox" >
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">Masters Degree</div>
                                            </div>
                                        </div>
                                        <div className="educationListBoxBody">
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Name of College/ University</label>
                                                <h4>{education.MastersCollege || 'No Data'}</h4>
                                            </div>
                                            {education.completedCourseMasters  &&
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completed?</label>
                                                    <h4>{education.completedCourseMasters}</h4>
                                                </div>
                                            }
                                            {education.completedCourseMasters === 'Yes' &&
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completion Date</label>
                                                    <h4>{education.MastersCompleteDate}</h4>
                                                </div>
                                            }
                                            {education.completedCourseMasters === 'No' &&                      
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Reason</label>
                                                    <h4>{education.MastersReason}</h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                                {education.completedCoursePhd  && (
                                    <div className="educationListBox" >
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">PhD Doctorate</div>
                                            </div>
                                        </div>
                                        <div className="educationListBoxBody">
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Name of College/ University</label>
                                                <h4>{education.PhdCollege}</h4>
                                            </div>
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Completed?</label>
                                                <h4>{education.completedCoursePhd}</h4>
                                            </div>
                                            {education.completedCoursePhd === 'Yes' &&
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completion Date</label>
                                                    <h4>{education.PhdCompleteDate}</h4>
                                                </div>
                                            }
                                            {education.completedCoursePhd === 'No' &&
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Reason</label>
                                                    <h4>{education.PhdReason}</h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    
                                )}
                            </>
                        )}

                        
                    </div>
                </div>
            </div>
            <div className="reviewBox">
                <div className="reviewBoxHead">
                    <h2 className="subTitle">Personal Details</h2>
                    <div className="reviewBoxHeadEditBtn">
                        <MdOutlineModeEdit />
                        <div className="reviewBoxHeadEditBtnText" onClick={() => handleEditClick(3)}>Edit</div>
                    </div>
                </div>
                <div className="reviewBoxBody">
                    <div className="formGroup">
                        <label htmlFor="">Gender</label>
                        {/* //{console.log(personalDetails)} */}
                        <h4>{personalDetails.gender_val ? personalDetails.gender_val :'Your Gender' }</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Marital status</label>
                        <h4>{personalDetails.marital_status ? personalDetails.marital_status :'Your Marital Status' }</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Date of Birth</label>
                        <h4>
                            {personalDetails.day_val && personalDetails.month_val && personalDetails.year_val 
                                ? `${personalDetails.day_val}-${personalDetails.month_val}-${personalDetails.year_val}` 
                                : 'DD-MM-YYYY'}
                        </h4>
                    </div>

                    <div className="formGroup">
                        <label htmlFor="">Permanent address</label>
                        <h4>{personalDetails.permenent_adress ? personalDetails.permenent_adress :'Your Permanent address' }</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Home Town</label>
                        <h4>{personalDetails.home_town ? personalDetails.home_town :'Your Home Town' }</h4>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">Pincode</label>
                        <h4>{personalDetails.pincode_val ? personalDetails.pincode_val :'Your Pincode' }</h4>
                    </div>
                    <div className="formGroup col-1">
                        <label htmlFor="">Language Proficiency</label>
                        <div className="languageTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Languages</th>
                                        <th>Proficiency</th>
                                        <th>Read</th>
                                        <th>Write</th>
                                        <th>Speak</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {language && language.length > 0 ? (
                                    language.map((lang) => (
                                        <tr key={lang.language_val}>
                                            <td>{lang.language_val}</td>
                                            <td>{lang.proficiancy_val}</td>
                                            <td>{lang.read_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                            <td>{lang.write_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                            <td>{lang.speak_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No languages listed</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

ReviewForm.propTypes = {
    setValue: PropTypes.func.isRequired,
};

export default ReviewForm;
