import React, { useState, useEffect } from "react";
import HomeContact from "../home/homeContact";
import { ImageGallery } from "react-image-grid-gallery";
import {Helmet} from "react-helmet";
import axios from 'axios';
import api, { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC'; // Axios interceptor
import emptyImg from '../../assets/images/emptyImg.jpg'
import { IoCallOutline } from "react-icons/io5";
function GalleryPage() {
const imageDemo =  [{ src: 'https://danielsjobfinder.com/demo3/hi' },
                    { src: 'https://danielsjobfinder.com/demo3/hi' },
                    { src: 'https://danielsjobfinder.com/demo3/hi' },
                    { src: 'https://danielsjobfinder.com/demo3/hi' },
                    ]
  const [imagesArray, setImagesArray] = useState(imageDemo);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch gallery images from API
  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await api.get(`${baseURL}/gallery-list`); // Replace with actual API endpoint
        // Assuming the response data is an array of image URLs
        console.log(response)
        const images = response.data.data.map(img => ({ src: mediaUrl+img.gallery_image }));
        setImagesArray(images);
      } catch (error) {
        console.log(error)
        setError('Failed to fetch gallery images');
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryImages();
  }, []);

  if (loading) {
    return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gallery | Discover Our Visual Stories | Daniel's Job Finder</title>
                <meta name="description" content="Browse our gallery to explore success stories of students and job seekers who achieved their dreams with Daniel's Job Finder. Discover their journeys with us" />
                <link rel="canonical" href="https://danielsjobfinder.com/gallery" />
                <meta name="keywords" content="study abroad programs in Europe, study abroad programs in Canada" />
                <meta property="og:title" content="Gallery | Discover Our Visual Stories | Daniel's Job Finder" />
                <meta property="og:description" content="Browse our gallery to explore success stories of students and job seekers who achieved their dreams with Daniel's Job Finder. Discover their journeys with us" />
            </Helmet>
            <section id="gallery">
              <div className="container">
                <div className="galleryTitle">
                  <h2 className="title">Gallery</h2>
                  {/* <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3> */}
                </div>
                <div className="galleryList">
                  
                </div>
              </div>
            </section>
            <HomeContact />
          </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="gallery">
        <div className="container">
          <div className="galleryTitle">
            <h2 className="title">Gallery</h2>
            {/* <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3> */}
          </div>
          <div className="galleryList">
            <ImageGallery
              imagesInfoArray={imagesArray}
              columnCount={"auto"}
              columnWidth={300}
              gapSize={20}
            />
          </div>
        </div>
      </section>
      <HomeContact />
    </>
  );
}

export default GalleryPage;
