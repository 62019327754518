import React, { useState, useEffect,useContext } from "react";
import { useNavigate,useParams } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton styles
import { Link,useLocation } from 'react-router-dom';
import HomeContact from "../home/homeContact";
import { MdArrowBack } from "react-icons/md";
import { GoLocation } from "react-icons/go";
// import { IoHeartOutline, IoHeart } from "react-icons/io5";
import { IoBookmarkOutline, IoBookmark } from "react-icons/io5";
import { PiMoneyWavy, PiBriefcaseLight } from "react-icons/pi";
import { HiMiniShare } from "react-icons/hi2";
import BackBtn from "../settings/backBtn";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoginPopupContext } from '../loginpopup/LoginPopupContext';

import companyLogo1 from '../../assets/images/compnay/logoIcon1.png'
import companyLogo2 from '../../assets/images/compnay/img1.webp'
import companyLogo3 from '../../assets/images/compnay/img2.svg'
import companyLogo4 from '../../assets/images/compnay/img3.png'
import companyLogo5 from '../../assets/images/compnay/img4.webp'
import companyLogo6 from '../../assets/images/compnay/img5.webp'
import companyLogo7 from '../../assets/images/logo.png'

const path = "";

const JobBox = lazy(() => import("../home/jobBox"));


function JobDetails() {
    const { id } = useParams();
    
    const [isScrolled, setIsScrolled] = useState(false);

    const [items, setItems] = useState([]);
    const[languages,setLanguages]=useState([]);
    const[weeklyoffs,setweeklyoffs]=useState([]);
    const[tags,settags]=useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state


    const navigate = useNavigate();

    // const handleSaveClick = () => {
    //     setSaved(prevSaved => !prevSaved);
    //     // toast(saved ? "Job removed from saved jobs" : "Job saved successfully!");
    //     toast(saved ? "Job removed from saved jobs" : "Job saved successfully!", {
    //         position: "top-right",
    //         autoClose: 2000, // 3 seconds
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         // progress: undefined,
    //         // theme: "colored",
    //     });
    // };

    const handleSaveClick = async (jobidval) => {
        const tokenval = localStorage.getItem('jwtToken');
        const roleval= localStorage.getItem('roleval');
        const shimmer = document.querySelector('.shimmer');
        if(tokenval && roleval=='candidate'){
        const login_id = localStorage.getItem('login_id');
        const newSavedState = !saved;
        setSaved(newSavedState);

        toast.success(newSavedState ? "Job saved successfully!" : "Job removed from saved jobs", {
            position: "top-right",
            autoClose: 2000, // 2 seconds
            hideProgressBar: false,
            closeOnClick: true,
            theme: "light",
            pauseOnHover: true,
            draggable: true,
        });

        try {
            const response = await axios.post(`${baseURL}/save_job_datas`, { login_id, jobidval, savedstatus: newSavedState });
            console.log(response.data);
            if (response.data.status === 1) {
                // Handle successful response
            } else {
                // Handle failure
            }
        } catch (error) {
            console.error("Error saving job:", error);
            toast.error("Failed to save the job", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "light",
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    else{
        shimmer.style.display = 'block'; 
        setIsLoginPopupVisible(true);   
        
    }
    };

    const handleShareClick = () => {
        if (navigator.share) {
            navigator.share({
                title: job_details.job_title,
                text: `Check out this job: ${job_details.job_title}`,
                url: window.location.origin
            }).then(() => {
                // toast.success("Job shared successfully!");
            })
            .catch((error) => {
                // toast.error("Error sharing the job: " + error);
            });
        } else {
            navigator.clipboard.writeText(window.location.origin).then(() => {
                toast.success("Job link copied to clipboard!");
            }).catch(() => {
                toast.error("Failed to copy link.");
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    
    // const location = useLocation(); 
    // const queryParams = new URLSearchParams(location.search);
    const jobId = id;
    const [jobData, setJobData] = useState({
        job_details: {},
        company_details: {},
        days_ago:'',
        saved_status:'',
      });

    useEffect(() => {
        const fetchJobDetails = async () => {
            setIsLoading(true);
            const login_id = localStorage.getItem('login_id');
            try {
                const response = await axios.get(`${baseURL}/fetch_job_details_candidate`, {
                    params: {
                      id: id,
                      login_id: login_id
                    }
                  });
                  console.log(response.data)
                setJobData(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJobDetails();
    }, [id]); // Dependency array includes id

      const { job_details, company_details,days_ago,saved_status } = jobData;

      const companyLogoPath = 'https://danielsjobfinder.com/demo3/public/';
      const companyLogoUrl = company_details.company_logo 
    ? `${companyLogoPath}${company_details.company_logo}` 
    : '';

    //qualification datas
    useEffect(() => {
        const fetchqualification = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${baseURL}/fetch_qualification_datas?id=${id}`);
                setItems(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchqualification();
    }, [id]); 
   
      //languages datas
      useEffect(() => {
        const fetchlanguage = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${baseURL}/fetch_languages_datas?id=${id}`);
                setLanguages(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchlanguage();
    }, [id]); 

      //weekly off datas
      useEffect(() => {
        const fetchweeklyoff = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${baseURL}/fetch_weeklyoff_datas?id=${id}`);
                setweeklyoffs(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchweeklyoff();
    }, [id]); 

    //tags datas
    useEffect(() => {
        const fetchtagdata = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${baseURL}/fetch_tags_datas?id=${id}`);
                settags(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchtagdata();
    }, [id]); 


      const [jobData1, setJobData1] = useState([]);
      //fetch job details
      useEffect(() => {
        const fetchrelated = async () => {
            const login_id = localStorage.getItem('login_id');
            setIsLoading(true);
            try {
                //const response = await axios.get(`${baseURL}/fetch_job_details_jobtype_related?id=${id}`);
                const response = await axios.get(`${baseURL}/fetch_job_details_jobtype_related`, {
                    params: {
                      id: id,
                      login_id: login_id
                    }
                  });
                 console.log(response.data) 
                setJobData1(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchrelated();
    }, [id]); 

    //   useEffect(() => {
    //     axios.get(`${baseURL}/fetch_job_details_jobtype_related?id=${jobId}`)
    //       .then(response => {
    //         console.log(response.data)
    //         setJobData1(response.data);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching job data:', error);
    //       });
    //   }, []);
      //const companyLogoPath = 'http://127.0.0.1:8000/';

      useEffect(() => {
        let saved_status1 = '';
        if (saved_status !== '') {
          if (saved_status === true) {
            saved_status1 = true;
          } else {
            saved_status1 = false;
          }
        } else {
          saved_status1 = true; 
        }
        setSaved(saved_status1); // Update state here
      }, [saved_status]); // Add saved_status as a dependency to rerun the effect when it changes
      
      const [saved, setSaved] = useState(saved_status);
      
      //console.log(saved_status1)

      const [isLoginPopupVisible, setIsLoginPopupVisible] = useContext(LoginPopupContext);
      const handleapplyjob = async (e) => {
        e.preventDefault();
        const tokenval = localStorage.getItem('jwtToken');
        const shimmer = document.querySelector('.shimmer');
        const roleval= localStorage.getItem('roleval');
        if(tokenval && roleval=='candidate'){
        navigate(`${path}/applyJobForm/${job_details.id}`);
        }
        else{
            shimmer.style.display = 'block'; 
            setIsLoginPopupVisible(true);   
            
        }
    }

  return (
    <>
        <section id="jobDetails">
            <div className={`jobApplyHeadStrip ${isScrolled ? 'jobApplyHeadStripActive' : ''}`}>
                <div className="container">
                    <div className="jobApplyHeadStripMain">
                        <div className="jobApplyHeadStripCol1">
                            <div className="jobApplyHeadStripProfile">
                                <div className="jobApplyHeadStripProfileIcon">
                                    <img src={companyLogo7} alt="" />
                                </div>
                                <div className="jobApplyHeadStripProfileTitle">
                                    <div className="jobCompany">{company_details.company_name}</div>
                                    <div className="jobTitleName">{job_details.job_title}</div>
                                </div>
                            </div>
                        </div>
                        <div className="jobApplyHeadStripCol2">
                            <Link to="#" onClick={handleapplyjob} className="jobApplyHeadStripApplyBtn">{job_details.apply_status}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="jobDetailsMain">
                    <BackBtn />
                    <div className="jobDetailsCol1">
                        <div className="jobDetailsBanner">
                            <div className="jobDetailsBannerHead">
                                <div className="jobDetailsBannerHeadCol1">
                                    <div className="jobDetailsBannerHeadProfile">
                                        <div className="jobDetailsBannerHeadProfileIcon">
                                            {/* <img src={companyLogo7} alt="" /> */}
                                            {isLoading ? <Skeleton width={60} height={60} circle={true} /> : <img src={companyLogo7} alt="Company Logo" />}
                                        </div>
                                        <div className="jobDetailsBannerHeadProfileTitle">
                                            {/* <div className="jobCompany">{company_details.company_name}</div> */}
                                            <div className="jobCompany">{isLoading ? <Skeleton width={150} /> : company_details.company_name}</div>
                                            {/* <div className="jobTitleName">{job_details.job_title}</div> */}
                                            <div className="jobTitleName">{isLoading ? <Skeleton width={200} /> : job_details.job_title}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="jobDetailsBannerHeadCol2">
                                    {isLoading ? <Skeleton width={50} height={50} circle={true} /> : <div className="jobDetailsBannerHeadShareBtn" onClick={handleShareClick}>
                                        <HiMiniShare />
                                    </div>}
                                    {isLoading ? <Skeleton width={50} height={50} circle={true} /> : <div className="jobDetailsBannerHeadSaveBtn" onClick={() => handleSaveClick(job_details.id)} jobidval={job_details.id}>
                                        {saved ? <IoBookmark style={{ color: 'black' }} /> : <IoBookmarkOutline />}
                                    </div>}
                                </div>
                            </div>
                            <div className="jobDetailsBannerBody">
                                <ul>
                                    {isLoading ? <Skeleton width={150} height={20} /> : <li>
                                        <div className="icon">
                                            <PiBriefcaseLight />
                                        </div>
                                        <p>{job_details.experience_range} Yrs</p>
                                    </li>}
                                    {isLoading ? <Skeleton width={150} height={20} /> : <li>
                                        <div className="icon">
                                            <PiMoneyWavy />
                                        </div>
                                        <p>{job_details.from_amount} - {job_details.to_amount} {job_details.salary_currency}</p>
                                    </li>}
                                    {isLoading ? <Skeleton width={150} height={20} /> : <li className="location">
                                        <div className="icon">
                                            <GoLocation />
                                        </div>
                                        <p>{job_details.location_val}</p>
                                    </li>}
                                    {isLoading ? <Skeleton width={150} height={20} /> : <li className="openings">
                                        <p>{job_details.remaining_openings} Opening</p>
                                    </li>}
                                </ul>
                            </div>
                            <div className="jobDetailsBannerFooter">
                                <div className="jobDetailsBannerFooterCol1">
                                    <div className="postedDate">
                                    {isLoading ? <Skeleton width={60} height={20} /> : <p>Posted {days_ago}</p>}
                                    </div>
                                </div>
                                <div className="jobDetailsBannerFooterCol2">
                                {isLoading ? <Skeleton width={100} height={40} /> : 
                                <Link 
                                to={job_details.apply_status === 'Applied' ? undefined : "#"}
                                onClick={job_details.apply_status === 'Applied' ? null : handleapplyjob} 
                                className={job_details.apply_status === 'Applied' ? "jobDetailsBannerApplyBtn jobDetailsBannerApplyBtnDisabled" : "jobDetailsBannerApplyBtn"} 
                                disabled={job_details.apply_status === 'Applied'}
                            >
                                {job_details.apply_status}
                            </Link>
                            
                                }
                                </div>
                            </div>
                        </div>
                        <div className="jobDetailsArea">
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Job Description</h2>
                                {isLoading ? <Skeleton count={5} /> : <div dangerouslySetInnerHTML={{ __html: job_details.job_description }} />}
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Qualifications</h2>
                                {isLoading ? <Skeleton count={5} /> : <ul>
                                {items.map(item => (
                                <li key={item.id}>{item.qualifications}</li>
                                ))}
                                </ul>}
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Languages</h2>
                                {isLoading ? <Skeleton count={5} /> : <ul>
                                {languages.map(item => (
                                <li key={item.id}>{item.value}</li>
                                ))}
                                </ul>}
                            </div>
                            {weeklyoffs.length > 0 && (
                                <div className="jobDetailsAreaBox">
                                    <h2 className="subTitle">Weekly Offs</h2>
                                    {isLoading ? <Skeleton count={5} /> : (
                                        <ul>
                                            {weeklyoffs.map(item => (
                                                <li key={item.id}>{item.value}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Skills</h2>
                                {isLoading ? <Skeleton count={5} /> : <ul>
                                {tags.map(item => (
                                <li key={item.id}>{item.tag_val}</li>
                                ))}
                                </ul>}
                            </div>
                        </div>
                        <div className="jobDetailsArea" style={{ display: 'none' }}>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">About company</h2>
                                {isLoading ? <Skeleton count={5} /> : <div dangerouslySetInnerHTML={{ __html: company_details.about_company }} />}
                            </div>
                            <div className="jobDetailsAreaBox">
                                <h2 className="subTitle">Company Info</h2>
                                <div className="companyDetails">
                                    <div className="companyDetailsBox">
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol1">
                                            <h3>Address</h3>
                                            <span>:</span>
                                        </div>}
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol2">
                                            <p>{company_details.adress_val}</p>
                                        </div>}
                                    </div>
                                    <div className="companyDetailsBox">
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol1">
                                            <h3>Website</h3>
                                            <span>:</span>
                                        </div>}
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol2">
                                            <Link to={`${company_details.website_val}`} target="_blank">{company_details.website_val}</Link>
                                        </div>}
                                    </div>
                                    <div className="companyDetailsBox">
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol1">
                                            <h3>Phone</h3>
                                            <span>:</span>
                                        </div>}
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol2">
                                            <Link to={`tel:+91${company_details.phone_number}`}>+91 {company_details.phone_number}</Link>
                                        </div>}
                                    </div>
                                    <div className="companyDetailsBox">
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol1">
                                            <h3>Email</h3>
                                            <span>:</span>
                                        </div>}
                                        {isLoading ? <Skeleton width={150} height={20} /> : <div className="companyDetailsBoxCol2">
                                            <Link to="mailto:info@esightsolutions.com">info@esightsolutions.com</Link>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jobDetailsCol2">
                        <h2 className="subTitle">Jobs you might be interested in</h2>
                        <div className="jobDetailsCol2JobList">
                                {jobData1.length > 0 ? (
                                        jobData1.map((job, index) => (
                        
                        <Suspense key={index} fallback={<div>Loading...</div>}>
                        <JobBox
                        jobLink={`${path}/jobDetails/${job.id}`}  // Use job ID or some unique identifier
                        jobCompanyImg={companyLogo7}  // Pass the image URL
                        jobCompanyName={job.company_name}  // Assuming the API returns 'company_name'
                        jobTitle={job.job_title}  // Assuming the API returns 'job_title'
                        jobLocation={job.location_val}  // Assuming the API returns 'location'
                        jobExperiance={job.experience_range}  // Adjust according to your data structure
                        jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}  // Assuming salary range is provided
                        jobOpening={job.no_openings}  // Number of openings
                        jobPostedDate={job.days_ago}  // Use 'days_ago' from the backend
                        applyJobLink={`${path}/applyJobForm/${job.id}`}
                        Shortdescription={job.short_description}
                        jobidval={job.id}
                        savedstatus={job.savedstatus}
                        />
                    </Suspense>


                    ))
                ) : (
                    <div className="no-data">No data found</div>
                )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <HomeContact />
    </>
  );
}

export default JobDetails;
