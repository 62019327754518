import React, { useState, useEffect, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import HomeContact from "../home/homeContact";
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC'; // Using axiosInterceptor for API requests
import emptyImg from '../../assets/images/emptyImg.jpg'
import emptyImg2 from '../../assets/images/emtyImg2.jpg'
import { IoCallOutline } from "react-icons/io5";
const HomeBlogBox = lazy(() => import("../home/homeBlogBox"));

const path = "";

function Blog() {
  // State for blogs
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch blogs data from API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await api.get(`${baseURL}/blog_list_front`); // Replace with your actual API endpoint
        setBlogs(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        setError("Failed to fetch blog data");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs | Discover Our Insights | Daniel's Job Finder</title>
                <meta name="description" content="Check out the Daniel’s Job Finder blog for job search tips, career advice, and insights, as well as job market trends to help you achieve your career goals." />
                <link rel="canonical" href="https://danielsjobfinder.com/blog" />
                <meta name="keywords" content="best time to apply for us visa, How to get International Passport" />
                <meta property="og:title" content="Blogs | Discover Our Insights | Daniel's Job Finder" />
                <meta property="og:description" content="Check out the Daniel’s Job Finder blog for job search tips, career advice, and insights, as well as job market trends to help you achieve your career goals." />
            </Helmet>
            <section id="blogSection">
            <div className="container">
                <div className="blogSectionTitle">
                <h2 className="title">Latest Blogs</h2>
                <h3 className="mainTitle">Unlocking Success, Simple Habits, Big Results</h3>
                </div>
                <div className="blogSectionList">
                
                    <Suspense key={1}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={2}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={3}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={4}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={5}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
                    <Suspense key={6}>
                        <HomeBlogBox 
                            homeBlogLink={`${path}/innerBlog/${''}`} 
                            homeBlogImg={'https://danielsjobfinder.com/demo3/public/hi'} // Use a fallback image if `imageUrl` is unavailable
                            homeBlogTime={''} 
                            homeBlogTitle={''} 
                            homeBlogDesc={''}
                        />
                    </Suspense>
               
                </div>
            </div>
            </section>

            <HomeContact />
        </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section id="blogSection">
        <div className="container">
          <div className="blogSectionTitle">
            <h2 className="title">Latest Blogs</h2>
            <h3 className="mainTitle">Unlocking Success, Simple Habits, Big Results</h3>
          </div>
          <div className="blogSectionList">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <Suspense key={blog.id}>
                  <HomeBlogBox 
                    homeBlogLink={`${path}/${blog.blog_title_format}/${blog.id}`} 
                    homeBlogImg={mediaUrl + blog.blog_image} // Use a fallback image if `imageUrl` is unavailable
                    homeBlogTime={blog.added_date} 
                    homeBlogTitle={blog.blog_title} 
                    homeBlogDesc={blog.blog_description}
                  />
                </Suspense>
              ))
            ):(
              <div className="noDataFound">
                  <div className="noDataFoundIcon">
                  <img src={emptyImg2} alt="" />
                  </div>
                  <div className="noDataFoundDetails">
                  <h3>Opps!</h3>
                  <p>Blog Not Available</p>
                  </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <HomeContact />
    </>
  );
}

export default Blog;
