import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import HomeContact from "../home/homeContact";
import api, { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC'; // Use your axiosInterceptor
import serviceImg1 from '../../assets/images/service/img1.jpg';

const path = "";
const website_path=mediaUrl;
//const website_path="https://danielsjobfinder.com/demo3/public/";

function InnerImmigrationService() {
  // Get service ID from URL params
  const { id } = useParams();
  const [imageLoaded, setImageLoaded] = useState(false);
  // State to hold service data
  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch service data from API
  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await api.get(`${baseURL}/serviceimmigration/${id}`); // Use the baseURL from interceptor
        setServiceData(response.data.data);
         console.log(response.data)
      } catch (error) {
        setError('Failed to fetch service data');
      } finally {
        setLoading(false);
      }
    };

    fetchServiceData();
  }, [id]); // Add id to the dependency array

  if (loading) {
    return <>
            <section id="innerServiceBanner">
            <div className="innerServiceBannerThumbnail">
                <img src={''}  />
            </div>
            <div className="container">
                <div className="innerServiceBannerDetails">
                <h1 className="mainTitle"></h1>
                </div>
            </div>
            </section>

            <section id="innerServiceContactStrip">
            <div className="container">
                <div className="innerServiceContactStripMain">
                <div className="innerServiceContactStripCol1">
                    <h2 className="title">If any doubt please contact us</h2>
                    <p>If you have any questions or concerns, feel free to reach out to us for assistance.</p>
                </div>
                <div className="innerServiceContactStripCol2">
                    <Link to="tel:00919447870999">Contact Now</Link>
                </div>
                </div>
            </div>
            </section>

            <section id="innerService">
            <div className="container">
                <div className="innerServiceMain">
                <p>{ "Service description not available."}</p>
                </div>
            </div>
            </section>

            <HomeContact />
        </>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
    {console.log(serviceData?.service_image)}
      <section id="innerServiceBanner">
        <div className="innerServiceBannerThumbnail">
          <img src={website_path+serviceData?.service_image || website_path+serviceImg1} alt="Service Banner" />
        </div>
        <div className="container">
          <div className="innerServiceBannerDetails">
            <h1 className="mainTitle">{serviceData?.service_title}</h1>
          </div>
        </div>
      </section>

      <section id="innerServiceContactStrip">
        <div className="container">
          <div className="innerServiceContactStripMain">
            <div className="innerServiceContactStripCol1">
              <h2 className="title">If any doubt please contact us</h2>
              <p>If you have any questions or concerns, feel free to reach out to us for assistance.</p>
            </div>
            <div className="innerServiceContactStripCol2">
              <Link to="tel:00919447870999">Contact Now</Link>
            </div>
          </div>
        </div>
      </section>

      <section id="innerService">
        <div className="container">
          <div className="innerServiceMain" dangerouslySetInnerHTML={{ __html:serviceData?.service_inner_description}} >
            
          </div>
        </div>
      </section>

      <HomeContact />
    </>
  );
}

export default InnerImmigrationService;
