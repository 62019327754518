import React ,{useState,useEffect}from "react";
import { Suspense, lazy } from 'react';
import { Link ,useLocation} from 'react-router-dom';
import { GoChevronRight } from "react-icons/go";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import companyLogo1 from '../../assets/images/compnay/logoIcon1.png'
import companyLogo2 from '../../assets/images/compnay/img1.webp'
import companyLogo3 from '../../assets/images/compnay/img2.svg'
import companyLogo4 from '../../assets/images/compnay/img3.png'
import companyLogo5 from '../../assets/images/compnay/img4.webp'
import companyLogo6 from '../../assets/images/compnay/img5.webp'
import companyLogo7 from '../../assets/images/logo.png'
import emptyImg from '../../assets/images/emptyImg.jpg'
import { IoCallOutline } from "react-icons/io5";

import axios from 'axios';

const path = "";

const JobBox = lazy(() => import("./jobBox"));

function HomeExploreJobs({searchFilters,setSearchFilters}) {
    const [jobData, setJobData] = useState([]);
    
    const location = useLocation();
    useEffect(() => {
      // Use searchFilters to fetch or filter job data
      const fetchFilteredJobs = () => {
        const login_id = localStorage.getItem('login_id');
        const { jobTitle, location, experience } = searchFilters;
  //console.log(jobTitle)
        axios.get(`${baseURL}/fetch_job_details_home`, {
          params: {
            login_id,
            jobTitle: jobTitle ? jobTitle : null,
            location: location ? location : null,
            experience: experience ? experience : null,
          }
        })
        .then(response => {
          console.log(response.data)
          setJobData(response.data);
        })
        .catch(error => {
          console.error('Error fetching job data:', error);
          if(error.status=='404'){
            setJobData('')
          }
        });
      };
  
      fetchFilteredJobs();
    }, [searchFilters]); // Trigger when searchFilters change


    useEffect(() => {
      const handleResetFilters = () => {
        setSearchFilters({
          jobTitle: null,
          location: null,
          experience: null,
        }); // Reset filters to initial values
      };
      handleResetFilters();
      const unlisten = location.key; // Trigger on page navigation
      return () => unlisten;
    }, [location]);
    const companyLogoPath = 'https://danielsjobfinder.com/demo3/public/';

    return (
        <section id="homeExploreJobs">
            <div className="container">
                <div className="titleHead">
                    <h2 className="title">Explore Jobs</h2>
                    <div className="titleMoreLink">
                        <Link to={`${path}/jobs`}>More Jobs <GoChevronRight /></Link>
                    </div>
                </div>
                <div className="homeExploreJobsMain">
                {jobData.length > 0 ? (
                                jobData.map((job, index) => (
              
              <Suspense key={index} fallback={<div>Loading...</div>}>
              <JobBox
                jobLink={`${path}/jobDetails/${job.id}`}  // Use job ID or some unique identifier
                jobCompanyImg={companyLogo7}  // Pass the image URL
                jobCompanyName={job.company_name}  // Assuming the API returns 'company_name'
                jobTitle={job.job_title}  // Assuming the API returns 'job_title'
                jobLocation={job.location_val}  // Assuming the API returns 'location'
                jobExperiance={job.experience_range}  // Adjust according to your data structure
                jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}  // Assuming salary range is provided
                jobOpening={job.no_openings}  // Number of openings
                jobPostedDate={job.days_ago}  // Use 'days_ago' from the backend
                applyJobLink={`${path}/applyJobForm/${job.id}`}
                Shortdescription={job.short_description}
                jobidval={job.id}
                savedstatus={job.savedstatus}
                applystatus={job.apply_status}
              />
            </Suspense>


            ))
        ) : (
          <div className="noDataFound">
            <div className="noDataFoundIcon">
            <img src={emptyImg} alt="" />
            </div>
            <div className="noDataFoundDetails">
            <h3>Opps!</h3>
            <p>If you don’t find what you are looking for, call/WhatsApp us on 0091 94478 70999 and we will be happy to personally find a good fit for you.</p>
            <Link to="tel:00919447870999" className="noDataFoundClearFIlterBtn">
                <IoCallOutline /> Call Now
            </Link>
            </div>
        </div>
        )}
                </div>
            </div>
        </section>
    );
}

export default HomeExploreJobs;
