import JobBanner from "./jobBanner";
import JobsWraper from "./jobsWraper";
import HomeContact from "../home/homeContact";
import {Helmet} from "react-helmet";


function Jobs() {
    
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Abroad Job Vacancies | Overseas & International Job Opportunities</title>
            <meta name="description" content="Get your dream abroad job with Daniel’s Job Finder. Discover the latest and best overseas and international job opportunities and advance your career right now." />
            <link rel="canonical" href="https://danielsjobfinder.com/jobs" />
            <meta name="keywords" content="overseas job consultants in kerala, Abroad Job Vacancies" />
            <meta property="og:title" content="Abroad Job Vacancies | Overseas & International Job Opportunities" />
            <meta property="og:description" content="Get your dream abroad job with Daniel’s Job Finder. Discover the latest and best overseas and international job opportunities and advance your career right now." />
        </Helmet>
        <JobBanner jobBannerTitle="Featured Jobs" />
        <JobsWraper />
        <HomeContact />
    </>
  );
}

export default Jobs;
