
import React,{useEffect,useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImGoogle } from "react-icons/im";
import loginImg from '../../assets/images/login.jpg'
import { toast } from 'react-toastify';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import { jwtDecode } from 'jwt-decode';
import { LoginPopupContext } from '../loginpopup/LoginPopupContext';

const path = "";

function Login() {

    const navigate = useNavigate();
   
    // const handleGetOTP = (e) => {
    //     e.preventDefault();
    //     // Here you can add logic to validate phone number or initiate OTP request.
    //     toast.info('OTP has been sent to your phone');
    //     navigate(`${path}/otp`);
    // };
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useContext(LoginPopupContext);
    useEffect(() => {
        const shimmer = document.querySelector('.shimmer');
        shimmer.style.display = 'none'; 
        setIsLoginPopupVisible(false);  
    })
      const handleFailure = (error) => {
        console.error('Google Sign-In failed:', error);
      };

      const handleSuccess = async (response) => {
        try {
          // Extract email from response
          const profile = jwtDecode(response.credential);  // Decode JWT to get profile data
          const email = profile.email;
          // Send the email to your Laravel backend for validation
          const res = await axios.post(`${baseURL}/loginaction`, {
            email: email,
          });
          console.log(res.data)
          if (res.data.status == 1) {
          localStorage.setItem('jwtToken', res.data.token);
          localStorage.setItem('login_id', res.data.login_id);
          localStorage.setItem('roleval', res.data.roleval);
                    // Navigate to the home page
                    setTimeout(() => {
                       // navigate(`${path}/`);
                       window.location.href = `${path}/`;
                    }, 200);
            }
            else{
                toast.info('Email id not exist!');
            }
        } catch (error) {
          console.error('Login Failed:', error);
        }
      };
  return (
    <>
        <section id="loginSection">
            <div className="container">
                <div className="loginSectionMain">
                    <div className="loginSectionThumbnail">
                        <img src={loginImg} alt="" />
                        <div className="loginSectionThumbnailDetails">
                            <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                        </div>
                    </div>
                    <div className="loginSectionDetails">
                        <h2 className='title'>Let's join with us</h2>
                        <p>Sign in and get back to exploring your opportunities.</p>
                        <form action="">
                            {/* <div className="formGroup">
                                <label htmlFor="">Enter Your Phone Number</label>
                                <input type="number" />
                                <div className="error">This phone number not registered</div>
                            </div>
                            <div className="formBtnArea">
                                <button onClick={handleGetOTP}>Get OTP</button>
                            </div> */}
                            {/* <div className="orText">Or</div> */}
                                    <GoogleOAuthProvider clientId="670316072274-q12um6refd1jnf16lp583kapoak3op6v.apps.googleusercontent.com">
                                    <div className="App">
                                        <h1></h1>
                                        <GoogleLogin onSuccess={handleSuccess} onError={handleFailure} />
                                    </div>
                                    </GoogleOAuthProvider>
                        </form>
                        <div className="logingFooterStrip">
                            <p>I don't have an account.</p>
                            <Link to={`${path}/register`}>Register Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Login;
